<template>
  <div class="ui teal basic button" @click="downloadCSV">現在表示している分を CSV ダウンロード</div>
</template>

<script>
export default {
  props: {
    resources: Array,
    columns: Object,
    fileName: String,
  },
  methods: {
    downloadCSV () {
      let resources = this.resources
      let columns = this.columns
      let fileName = this.fileName
      const excludeColumns = ['id', 'send_point']
      const timeColoumn = ['created_at', 'updated_at', 'release_time', 'expiration_time', 'distributed_at']
      let vv = []
      let h = []
      for (let key in columns) {
        if (!excludeColumns.includes(key)) {
          h.push(columns[key])
        }
      }
      vv.push(h)
      resources.forEach(resource => {
        let a = []
        for (let key in columns) {
          if (!excludeColumns.includes(key)) {
            if (timeColoumn.includes(key)) {
              a.push(this.formatTime(resource[key]))
            } else {
              a.push(resource[key])
            }
          }
        }
        vv.push(a)
      })
      let rs = []
      vv.forEach(v => {
        let es = []
        v.forEach(e => {
          if (typeof(e) === "undefined") {
            es.push('""')
          } else {
            let s = e.toString().replace(/"/g, '""')
            es.push(`"${s}"`)
          }
        })
        let r = es.join(',')
        rs.push(r)
      })
      let csv = rs.join('\n')
      csv = `\ufeff${csv}`
      let blob = new Blob([csv], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    },
  }
}
</script>

