import api from '../../api'

const state = {
  user: null,
  users: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getUser ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getUser, payload)
    if (res !== null) {
      const user = res.data.profile
      commit('setUser', user)
    }
    commit('setLoading', false)
  },
  async getNewUsers ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getUsers, payload)
    if (res !== null) {
      const users = res.data.profiles
      commit('setUsers', users)
      if (users.length > 0) {
        commit('setSinceId', users[users.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextUsers ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getUsers, payload)
    if (res !== null) {
      const users = res.data.profiles
      commit('appendUsers', users)
      if (users.length > 0) {
        commit('setSinceId', users[users.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async delete ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.deleteUser, payload)
    if (res !== null) {
      // nothing
    }
    commit('setLoading', false)
  },
}

const mutations = {
  setUser (state, user) {
    state.user = modify(user)
  },
  setUsers (state, users) {
    let us = []
    users.forEach(u => {
      us.push(modify(u))
    })
    state.users = us
  },
  appendUsers (state, users) {
    users.forEach(u => {
      state.users.push(modify(u))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  },
}

function modify(usr) {
  let u = usr
  if (usr.twitter_id) {
    u.twitter_url = `https://twitter.com/intent/user?user_id=${usr.twitter_id}`
  }
  return u
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}