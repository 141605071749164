<template>
  <div class="create-benefit">
    <Wrapper :title="'特典作成'">
      <h3>特典を新規作成する</h3>
      <ValidationObserver v-slot="{ invalid }">
        <div class="ui form">
          <div class="field">
            <h4>特典タイトル<span class="required"> *</span> {{ name ? name.length : 0 }} /20</h4>
            <ValidationProvider
              name="特典タイトル"
              rules="min:3|max:20|required"
              v-slot="{ errors }"
            >
              <input v-model="name" />
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="field">
            <h4>特典の形態<span class="required"> *</span></h4>
            <select v-model="feature" class="ui dropdown">
              <option value="remote">直接会わずに提供できるサービスです</option>
              <option value="in_place">直接会って提供するサービスです</option>
              <option value="pass">会員証型のサービスです</option>
            </select>
          </div>
          <div class="field">
            <h4>特典の説明<span class="required"> *</span> {{ description ? description.length : 0 }} /1000</h4>
            <ValidationProvider
              name="特典の説明"
              rules="min:1|max:1000|required"
              v-slot="{ errors }"
            >
              <textarea rows="4" cols="50" v-model="description" />
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="field">
            <div class="ui checkbox">
              <input type="checkbox" @change="initAmount" v-model="isForGive">
              <label>譲渡用の特典を作成する</label>
            </div>
          </div>
          <div v-if="!isForGive" class="field">
            <h4>交換に必要なポイント数<span class="required"> *</span></h4>
            <ValidationProvider
              name="交換に必要なポイント数"
              rules="required|numeric|between:1,100000"
              v-slot="{ errors }"
            >
              <input v-model="amount" />
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="field">
            <div class="ui checkbox">
              <input type="checkbox" @change="initLimit" v-model="isLimited">
              <label>上限や期限を設定する</label>
            </div>
          </div>
          <div v-if="isLimited">
            <div class="field">
              <h4>特典チケットの最大交換数（0 は上限なし）</h4>
              <ValidationProvider
                name="特典チケットの最大交換数"
                rules="numeric|between:0,1000000"
                v-slot="{ errors }"
              >
                <input v-model="maxTickets" />
                <span class="err">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="field">
              <h4>交換期限</h4>
              <input v-model="expireTime" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
            </div>
            <div class="field">
              <h4>特典チケットの使用期限（これを指定する場合は交換期限も指定し、交換期限より後の時刻を指定してください）</h4>
              <input v-model="ticketExpireTime" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
            </div>
            <br />
          </div>
          <div class="field">
            <h4>特典の画像を設定する</h4>
            <input type="file" @change="onDrop"/>
            <div v-if="uploadedImage"><img :src="uploadedImage" width="50%"></div>
          </div>
          <div class="field">
            <div class="ui checkbox">
              <input type="checkbox" @change="initStartTime" v-model="withStartTime">
              <label>作成を予約する</label>
            </div>
          </div>
          <div v-if="withStartTime">
            <div class="field">
              <h4>特典を作成する日時</h4>
              <input v-model="startTime" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
            </div>
          </div>
          <button :class="{'loading': (benefit.loading || image.loading)}" :disabled="invalid || benefit.loading || image.loading" class="ui teal basic button" @click="create">作成</button>
        </div>
      </ValidationObserver>
    </Wrapper>
  </div>
</template>
<script>
import Wrapper from '@/components/Wrapper.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Wrapper: Wrapper,
  },
  data () {
    return {
      pointId: '',
      name: '',
      feature: 'remote',
      description: '',
      amount: null,
      isForGive: false,
      file: null,
      uploadedImage: null,
      isLimited: false,
      maxTickets: 0,
      expireTime: null,
      ticketExpireTime: null,
      startTime: null,
      withStartTime: false,
    }
  },
  computed: {
    ...mapState(['point', 'benefit', 'image']),
  },
  created () {
    this.pointId = this.$route.params.pointId
  },
  methods: {
    initAmount() {
      this.amount = null
    },
    initLimit() {
      this.maxTickets = 0
      this.expireTime = null
      this.ticketExpireTime = null
    },
    initStartTime() {
      this.startTime = null
    },
    onDrop (event) {
      this.file = event.target.files[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        this.uploadedImage = reader.result
      }
      if (this.file) {
        reader.readAsDataURL(this.file);
      }
    },
    async create () {
      let et = this.expireTime + ':00+09:00'
      let tet = this.ticketExpireTime + ':00+09:00'
      let st = this.startTime + ':00+09:00'
      if (this.expireTime === null) et = null
      if (this.ticketExpireTime === null) tet = null
      if (this.startTime === null) st = null
      const start = st ? (new Date() > new Date(st)) : true
      if (tet !== null && et === null) {
        alert('特典チケットの使用期限を指定する場合は交換期限も指定してください')
        return
      }
      if (et && tet && new Date(et) > new Date(tet)) {
        alert('特典チケットの使用期限は交換期限よりも後の時刻で指定してください')
        return
      }
      if (this.isForGive) {
        this.amount = 0
      }
      const createBenefitRes = await this.$store.dispatch('benefit/createBenefit', {
        org: this.pointId,
        name: this.name,
        description: this.description,
        feature: this.feature,
        usage_limit_per_person: -1,
        amount: Number(this.amount),
        is_visible: !this.isForGive,
        start: start,
        start_time: st,
        max_tickets: Number(this.maxTickets),
        expire_time: et,
        ticket_expire_time: tet,
      })
      if (createBenefitRes === null) {
        return
      }
      if (this.file) {
        let data = new FormData()
        data.append('id', this.benefit.benefit.profile_image.id)
        data.append('files[]', this.file)
        await this.$store.dispatch('image/uploadImage', data)
      }
      alert('特典を新規作成しました')
      this.$router.push(`/points/${this.pointId}/benefits`)
    }
  }
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>