import api from '../../api'

const state = {}

const getters = {}

const actions = {
  /* eslint no-unused-vars: 0 */
  async getAccessToken ({ commit }, payload) {
    const res = await api.getAccessToken(payload)
    localStorage.setItem('access_token', res.data.access_token)
    localStorage.setItem('refresh_token', res.data.refresh_token)
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}