<template>
<div class="root">
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  async mounted() {
    if (localStorage.getItem('access_token')) {
      this.$router.push(`/points`)
    } else {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapState(['point']),
  },
}
</script>