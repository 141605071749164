<template>
  <div class="end-benefit">
    <button
      :class="{'loading': loading}"
      :disabled="loading"
      @click="onClick"
      class="ui teal basic button"
    >
      特典を終了する
    </button>
  </div>
</template>

<script>
export default {
  props: {
    benefit: Object,
    onClick: Function,
    loading: Boolean,
  },
}
</script>