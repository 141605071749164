<template>
  <div class="holders">
    <Wrapper :title="'保有者一覧'">
      <div class="ui form">
        <div class="field">
          <select v-model="orderBy" class="ui fluid dropdown">
            <option value="-updated_at">利用日の新しい順</option>
            <option value="updated_at">利用日の古い順</option>
            <option value="-created_at">初回獲得日の新しい順</option>
            <option value="created_at">初回獲得日の古い順</option>
          </select>
        </div>
        <div class="field">
          <div class="ui teal basic button" @click="fetchNew">表示</div>
        </div>
        <div class="field">
          <DownloadCSV
            :resources="holder.holders"
            :columns="columnsWithWallet"
            :fileName="'holders.csv'"
          />
        </div>
      </div>

      <table class="ui celled striped table">
      <thead><tr>
        <th v-for="value in columns" :key="value.id">
          {{ value }}
        </th>
      </tr></thead>
      <tbody><tr v-for="holder in holder.holders" :key="holder.id">
        <td v-for="(value, key) in columns" :key="value.id">
          <div v-if="key=='created_at' || key=='updated_at'">
            {{ holder[key] | formatDate }}
          </div>
          <div v-else-if="key==='wallet'">
            <router-link :to="{ path: '/points/' + pointId + '/holders/' + holder[key] }" class="ui teal basic button">詳細</router-link>
          </div>
          <div v-else>
            {{ holder[key] }}
          </div>
        </td>
      </tr></tbody>
      </table>
      <div v-if="holder.loading"><div class="ui active inline loader"></div></div>

    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Holders',
  components: {
    Wrapper: Wrapper,
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const limit = 30
    const columns = {
      'name': '名前',
      'screen_name': 'スクリーンネーム',
      'balances': '残高',
      'created_at': '初回獲得日',
      'updated_at': '直近の利用日',
      'wallet': '詳細',
    }
    return {
      columns: columns,
      limit: limit,
      orderBy: '-updated_at',
      sinceId: null,
    }
  },
  computed: {
    ...mapState([ 'holder', 'point' ]),
    columnsWithWallet () {
      let c = Object.assign({}, this.columns)
      c.wallet = 'ウォレットID'
      return c
    },
  },
  created () {
    this.pointId = this.$route.params.pointId
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.holder.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('holder/getNewHolders', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.holder.all) return
      this.$store.dispatch('holder/getNextHolders', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.holder.sinceId,
      })
    }
  },
}
</script>
