<template>
<div class="container" :class="isSmall ? 'small' : ''">
  <slot />
</div>
</template>

<script>
export default {
  props: {
    isSmall: Boolean,
  },
}
</script>

<style scoped>
.container {
  margin-top: 70px;
  margin-left: 50px;
  margin-right: 50px;
}
.small {
  width: 70%;
}
</style>