<template>
  <div class="copiable-content">
    {{ text }}
    <CopyToClipboardButton
      class="button"
      :text="text"
      :name="name"
    />
  </div>
</template>

<script>
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';

export default {
  components: {
    CopyToClipboardButton,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped>
.copiable-content {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.button {
  margin-left: 3px;
}
</style>