<template>
  <td class="ui center aligned">
  <button class="ui teal basic button" v-if="!loading && !tried" @click="download">取得</button>
  <button class="ui teal basic button" v-if="!loading && tried" @click="download">再取得</button>
  <div v-if="loading"><div class="ui active inline loader"></div></div>
  <div v-if="image"><img :src="image"></div>
  </td>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ImageDownloader',
  props: {
    imageId: {
      required: true
    }
  },
  data () {
    return {
      image: null,
      loading: false,
      tried: false
    }
  },
  methods: {
    download: function () {
      this.loading = true
      this.tried = true
      let url = process.env.VUE_APP_API_BASE_URL + '/image/v0/images/' + this.imageId + '/download'
      axios.get(url, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.access_token
        },
        responseType: 'blob'
      }).then(response => {
        this.loading = false
        // console.log(response.data)
        var reader = new FileReader()
        reader.onloadend = () => {
          this.image = reader.result
        }
        reader.readAsDataURL(response.data)
        // console.log(this.imageSrouce)
      }).catch(error => {
        this.loading = false
        alert(error)
        // console.log(error)
      })
    }
  }
}
</script>

<style scoped>
img {
  margin-top: 15px;
  width: 300px;
}
</style>
