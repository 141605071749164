import api from '../../api'

const state = {
  connections: [],
}

const getters = {}

const actions = {
  async listOidc ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.listOidc, payload)
    if (res !== null) {
      const connections = res.data.connections
      commit('setConnections', connections)
    }
  },
  async createOidc ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.createOidc, payload)
    if (res !== null) {
      const connections = res.data.connections
      commit('setConnections', connections)
      return res
    }
    return null
  },
  async updateOidc ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.updateOidc, payload)
    if (res !== null) {
      const connections = res.data.connections
      commit('setConnections', connections)
      return res
    }
    return null
  },
  async deleteOidc ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.deleteOidc, payload)
    if (res !== null) {
      const connections = res.data.connections
      commit('setConnections', connections)
      return res
    }
    return null
  },
}

const mutations = {
  setConnections (state, connections) {
    state.connections = connections
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}