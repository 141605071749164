var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"generate-stamp-pdf"},[_c('Wrapper',{attrs:{"title":'QRコード印刷'}},[_c('h3',[_vm._v("QRコードをまとめて印刷する")]),_c('p',[_vm._v(" 一度だけ読み取れるQRコードをまとめてPDF形式で出力します。"),_c('br'),_vm._v(" QRコードの有効期限は発行日から1年間です。"),_c('br'),_vm._v(" 指定のシール台紙（エーワンラベルシールハイグレードタイプ 24面 20シート 75624）をご利用いただくと綺麗に印刷ができます。印刷サイズは100%に設定してください。"),_c('br'),_vm._v(" ＜活用例＞"),_c('br'),_vm._v(" ・UberEatsなどデリバリーの商品に貼ってポイント付与"),_c('br'),_vm._v(" ・グッズにシールを貼ることで物販のポイント付与を簡単にする ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"ui form"},[_c('div',{staticClass:"field"},[_c('h4',[_vm._v("ポイント数"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"ポイント数","rules":"required|numeric|between:1,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("シート数"),_c('span',{staticClass:"required"},[_vm._v(" *")]),_vm._v(" (この枚数 * 24個のQRコードが作成されます)")]),_c('ValidationProvider',{attrs:{"name":"シート数","rules":"required|numeric|between:1,30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sheetCnt),expression:"sheetCnt"}],domProps:{"value":(_vm.sheetCnt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.sheetCnt=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("受信者へのメッセージ"+_vm._s(_vm.message ? _vm.message.length : 0)+"/600")]),_c('ValidationProvider',{attrs:{"name":"受信者へのメッセージ","rules":"max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],attrs:{"rows":"4","cols":"50"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"ui teal basic button",attrs:{"disabled":invalid || _vm.stampCreated || _vm.stamp.loading},on:{"click":_vm.createStamps}},[_vm._v("QRコードを作成")])]),(_vm.stamp.loading)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('progress-bar',{attrs:{"options":_vm.options,"value":_vm.percentage}})],1):_vm._e(),(_vm.stampCreated)?_c('div',{staticStyle:{"margin-top":"10px"}},[_c('button',{staticClass:"ui teal basic button",on:{"click":function($event){return _vm.generatePDF(false)}}},[_vm._v("PDFを新しいタブを開いて表示")]),_c('button',{staticClass:"ui teal basic button",attrs:{"disabled":_vm.downloadStarted},on:{"click":function($event){return _vm.generatePDF(true)}}},[_vm._v("PDFをダウンロード（5枚以上のときはこちらを推奨）")]),_c('p',[_vm._v("✳︎30枚のダウンロードには5分程度かかります")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }