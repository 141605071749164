<template>
  <div class="wallets">

    <div class="ui form">
      <div class="field">
        <DownloadCSV
          :resources="wallet.wallets"
          :columns="columns"
          :fileName="'wallets.csv'"
        />
      </div>
    </div>

    <table class="ui celled striped table">
      <thead><tr>
        <th v-for="value in columns" :key="value.id">
          {{ value }}
        </th>
        <th>
          ホルダー詳細
        </th>
      </tr></thead>
      <tbody>
        <tr v-for="wallet in wallet.wallets" :key="wallet.id">
          <td v-for="(value, key) in columns" :key="value.id">
            <div v-if="key=='created_at' || key=='updated_at'">
              {{ wallet[key] | formatDate }}
            </div>
            <div v-else-if="key==='point_id'">
              <router-link :to="{ path: '/points/' + wallet[key] }" class="ui teal basic button">詳細</router-link>
            </div>
            <div v-else>
              {{ wallet[key] }}
            </div>
          </td>
          <td>
            <router-link :to="{ path: '/points/' + wallet.point_id + '/holders/' + wallet.id }" class="ui teal basic button">ホルダー詳細</router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="wallet.loading"><div class="ui active inline loader"></div></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Wallets',
  components: {
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const columns = {
      'point_name': 'ポイント名',
      'balances': '残高',
      'point_id': 'ポイント詳細',
    }
    return {
      columns: columns,
    }
  },
  props: {
    userId: String,
  },
  computed: {
    ...mapState([ 'wallet', ]),
  },
  created () {
    this.fetchNew()
  },
  methods: {
    fetchNew: function () {
      this.$store.dispatch('wallet/getNewWallets', {
        user_id: this.userId,
        limit: 100,
      })
    },
  },
}
</script>