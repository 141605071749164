<template>
  <button
    :class="{'loading': ending}"
    :disabled="ending"
    class="ui teal basic button"
    @click="endStamp"
  >
    QRコードを終了する
  </button>
</template>

<script>
export default {
  name: 'EndStamp',
  data () {
    return {
      ending: false,
    }
  },
  props: {
    stampId: String,
  },
  methods: {
    async endStamp () {
      if (!window.confirm('このQRコードを終了しますか？')) {
        return
      }
      this.ending = true
      await this.$store.dispatch('stamp/endStamp', {
        id: this.stampId,
      })
      alert('終了しました')
      this.ending = false
    }
  }
}
</script>