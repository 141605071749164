<template>
  <div class="pendingUsers">

  <Wrapper :title="'本人確認申請ユーザー一覧'" :hasInnerMenu="false">
    <table class="ui celled striped table">
    <thead><tr>
      <th v-for="value in columns" :key="value.id">
        {{ value }}
      </th>
    </tr></thead>
    <tbody><tr v-for="pendingUser in pendingUser.pendingUsers" :key="pendingUser.id">
      <td v-for="(value, key) in columns" :key="value.id">
        <div v-if="key==='document_front_image_id'">
          <ImageDownloader
            :imageId="pendingUser[key]"
          />
        </div>
        <div v-else-if="key==='verify'">
          <VerifyButton
            :userId="pendingUser.id"
            :userRealName="pendingUser.real_name"
          />
        </div>
        <div v-else>
          {{ pendingUser[key] }}
        </div>
      </td>
    </tr></tbody>
    </table>
    <div v-if="pendingUser.loading"><div class="ui active inline loader"></div></div>
  </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import ImageDownloader from '@/components/ImageDownloader'
import VerifyButton from '@/components/VerifyButton'

export default {
  name: 'PendingUsers',
  components: {
    Wrapper: Wrapper,
    ImageDownloader: ImageDownloader,
    VerifyButton: VerifyButton,
  },
  data: function () {
    const columns = {
      'email': 'Eメール',
      'real_name': '本名',
      'birthday': '誕生日',
      'document_front_image_id': '書類の画像',
      'verify': '承認・拒否',
    }
    return {
      columns: columns,
    }
  },
  computed: {
    ...mapState([ 'pendingUser', ]),
  },
  created () {
    this.fetchNew()
  },
  methods: {
    fetchNew: function () {
      this.$store.dispatch('pendingUser/getNewPendingUsers', {})
    },
  },
}
</script>