import api from '../../api'

const state = {
  tweets: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getNewTweets ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTweets, payload)
    if (res !== null) {
      const tweets = res.data.tweets
      commit('setTweets', tweets)
      if (tweets.length > 0) {
        commit('setSinceId', tweets[tweets.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextTweets ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTweets, payload)
    if (res !== null) {
      const tweets = res.data.tweets
      commit('appendTweets', tweets)
      if (tweets.length > 0) {
        commit('setSinceId', tweets[tweets.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  }
}

const mutations = {
  setTweets (state, tweets) {
    let tws = []
    tweets.forEach(tw => {
      tws.push(modify(tw))
    })
    state.tweets = tws
  },
  appendTweets (state, tweets) {
    tweets.forEach(tw => {
      state.tweets.push(modify(tw))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

function modify(tw) {
  let t = tw
  t.cnt = 1 // 追加送信のため
  return t
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}