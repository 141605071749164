<template>
  <div class="transactions">

  <div class="ui form">
    <div class="field">
      <select v-model="orderBy" class="ui fluid dropdown">
        <option value="-created_at">送信日時の新しい順</option>
        <option value="created_at">送信日時の古い順</option>
      </select>
    </div>
    <div class="field">
      <div class="ui checkbox">
        <input type="checkbox" @change="initDuration" v-model="withDuration">
        <label>期間を指定する</label>
      </div>
    </div>
    <div v-if="withDuration" class="two field">
      <div class="field">
        <h4>期間指定の開始時間</h4>
        <input v-model="oldest" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
      </div>
      <div class="field">
        <h4>期間指定の終了時間</h4>
        <input v-model="latest" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
      </div>
    </div>
    <div class="field">
      <div class="ui checkbox">
        <input type="checkbox" @change="initRoute" v-model="withRoute">
        <label>送信経路を指定する</label>
      </div>
    </div>
    <div v-if="withRoute" class="field">
      <h4>送信経路</h4>
      <select v-model="route" class="ui fluid dropdown">
        <option value="stamp">QRコード経由の送信</option>
        <option value="benefit">特典交換による送信</option>
        <option value="campaign">キャンペーン経由の送信</option>
      </select>
    </div>
    <div class="field">
      <div class="ui teal basic button" @click="fetchNew">表示</div>
    </div>
    <div class="field">
      <DownloadCSV
        :resources="transaction.transactions"
        :columns="columns"
        :fileName="'transactions.csv'"
      />
    </div>
  </div>

  <table class="ui celled striped table">
  <thead><tr>
    <th v-for="value in columns" :key="value.id">
      {{ value }}
    </th>
  </tr></thead>
  <tbody><tr v-for="tx in transaction.transactions" :key="tx.id">
    <td v-for="(value, key) in columns" :key="value.id">
      <div v-if="key==='id'">
        <router-link :to="{ path: '/points/' + pointId + '/transactions/' + tx[key] }" class="ui teal basic button">詳細</router-link> 
      </div>
      <div v-else-if="key==='created_at'">
        {{ tx[key] | formatTime }}
      </div>
      <div v-else>
        {{ tx[key] }}
      </div>
    </td>
  </tr></tbody>
  </table>
  <div v-if="transaction.loading"><div class="ui active inline loader"></div></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'transactions',
  components: {
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const limit = 30
    const columns = {
      'amount': 'ポイント数',
      'created_at': '送信日時',
      'from_name': '送信者',
      'to_name': '受信者',
      'id': '詳細',
    }
    return {
      pointId: null,
      columns: columns,
      limit: limit,
      orderBy: '-created_at',
      withDuration: false,
      oldest: null,
      latest: null,
      withRoute: false,
      route: null,
    }
  },
  props: {
    stampId: String,
    walletId: String,
  },
  computed: mapState([
    'transaction',
  ]),
  created () {
    this.pointId = this.$route.params.pointId
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    initDuration () {
      this.oldest = null
      this.latest = null
    },
    initRoute () {
      this.route = null
    },
    pager: function () {
      if (!this.transaction.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('transaction/getNewTransactions', {
        point_id: this.pointId,
        stamp_id: this.stampId,
        wallet_id: this.walletId,
        limit: this.limit,
        order_by: this.orderBy,
        oldest: this.oldest,
        latest: this.latest,
        route: this.route,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.transaction.all) return
      this.$store.dispatch('transaction/getNextTransactions', {
        point_id: this.pointId,
        stamp_id: this.stampId,
        wallet_id: this.walletId,
        limit: this.limit,
        order_by: this.orderBy,
        oldest: this.oldest,
        latest: this.latest,
        route: this.route,
        since_id: this.transaction.sinceId,
      })
    },
  },
}
</script>
