import moment from 'moment'

export default {
  methods: {
    randomString(len) {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz-._~'
      const l = chars.length
      let str = ''
      for (let i = 0; i < len; i++) {
        str += chars[Math.floor(Math.random() * l)]
      }
      return str
    },
    formatTime(date) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss')
    },
    formatTimePost(date) {
      return moment(date).format('YYYY-MM-DDTHH:mm:ss+09:00')
    },
  }
}