var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-stamps"},[_c('Wrapper',{attrs:{"title":'QRコード作成'}},[_c('h3',[_vm._v("QRコードを新規作成する")]),_c('p',[_vm._v(" 複数人が読み取れるQRコードを発行できます。"),_c('br'),_vm._v(" QRコードの有効期限を指定しない場合は，発行日から10年間です。 ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"ui form"},[_c('div',{staticClass:"field"},[_c('h4',[_vm._v("QRコードの種類"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.spec),expression:"spec"}],staticClass:"ui dropdown",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.spec=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"default"}},[_vm._v("1回のみ")]),_c('option',{attrs:{"value":"once_daily"}},[_vm._v("1日1回")]),_c('option',{attrs:{"value":"community_store_checkin"}},[_vm._v("コミュニティストアチェックイン")]),_c('option',{attrs:{"value":"community_store_intro"}},[_vm._v("コミュニティストアイントロ")]),_c('option',{attrs:{"value":"no_limit"}},[_vm._v("読み取り回数無制限")])])]),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("ポイント数"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"ポイント数","rules":"required|numeric|between:1,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.amount=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("名前（ユーザーには表示されません） "+_vm._s(_vm.name ? _vm.name.length : 0)+" /100")]),_c('ValidationProvider',{attrs:{"name":"名前","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("説明（ユーザーには表示されません） "+_vm._s(_vm.description ? _vm.description.length : 0)+" /100")]),_c('ValidationProvider',{attrs:{"name":"説明","rules":"max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],attrs:{"rows":"4","cols":"50"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"two fields"},[_c('div',{staticClass:"field"},[_c('h4',[_vm._v("開始日時（現在より後を指定。指定しない場合は現在）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.release_time),expression:"release_time"}],attrs:{"type":"datetime-local","min":"2000-01-01T00:00","max":"2100-01-01T00:00"},domProps:{"value":(_vm.release_time)},on:{"input":function($event){if($event.target.composing){ return; }_vm.release_time=$event.target.value}}})]),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("終了日時 （開始日時よりも後を指定。指定しない場合は現在から10年後）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.expiration_time),expression:"expiration_time"}],attrs:{"type":"datetime-local","min":"2000-01-01T00:00","max":"2100-01-01T00:00"},domProps:{"value":(_vm.expiration_time)},on:{"input":function($event){if($event.target.composing){ return; }_vm.expiration_time=$event.target.value}}})])]),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("受信者へのメッセージ "+_vm._s(_vm.message ? _vm.message.length : 0)+"/600")]),_c('ValidationProvider',{attrs:{"name":"受信者へのメッセージ","rules":"max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],attrs:{"rows":"4","cols":"50"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('div',{staticClass:"ui checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isLocation),expression:"isLocation"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isLocation)?_vm._i(_vm.isLocation,null)>-1:(_vm.isLocation)},on:{"change":[function($event){var $$a=_vm.isLocation,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isLocation=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isLocation=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isLocation=$$c}},_vm.initLocation]}}),_c('label',[_vm._v("取得可能な位置情報を指定する")])])]),(_vm.isLocation)?_c('div',[_c('div',{staticClass:"two fields"},[_c('div',{staticClass:"field"},[_c('h4',[_vm._v("緯度"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"緯度","rules":"decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.latitude),expression:"latitude"}],domProps:{"value":(_vm.latitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.latitude=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("経度"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"経度","rules":"decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.longitude),expression:"longitude"}],domProps:{"value":(_vm.longitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.longitude=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("取得可能距離（設定しない場合はデフォルトで 30m になります。単位は m です）")]),_c('ValidationProvider',{attrs:{"name":"取得可能距離","rules":"decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.capturable_distance),expression:"capturable_distance"}],domProps:{"value":(_vm.capturable_distance)},on:{"input":function($event){if($event.target.composing){ return; }_vm.capturable_distance=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('br'),_c('button',{staticClass:"ui teal basic button",attrs:{"disabled":invalid},on:{"click":_vm.create}},[_vm._v("作成")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }