<template>
  <div class="notices">

  <Wrapper :title="'お知らせ一覧'">
    <div class="ui form">
      <div class="field">
        <select v-model="orderBy" class="ui fluid dropdown">
          <option value="-updated_at">公開日時の新しい順</option>
          <option value="updated_at">公開日時の古い順</option>
        </select>
      </div>
      <div class="field">
        <div class="ui teal basic button" @click="fetchNew">表示</div>
      </div>
      <div class="field">
        <DownloadCSV
          :resources="notice.notices"
          :columns="columns"
          :fileName="'notices.csv'"
        />
      </div>
    </div>

    <table class="ui celled striped table">
    <thead><tr>
      <th v-for="value in columns" :key="value.id">
        {{ value }}
      </th>
    </tr></thead>
    <tbody><tr v-for="notice in notice.notices" :key="notice.id">
      <td v-for="(value, key) in columns" :key="value.id">
        <div v-if="key==='id'">
          <router-link :to="{ path: '/points/' + pointId + '/notices/' + notice[key] }" class="ui teal basic button">詳細</router-link> 
        </div>
        <div v-else-if="key=='release_time'">
          {{ notice[key] | formatMinute }}
        </div>
        <div v-else-if="key==='image_url'">
          <div v-if="notice[key]">
            <img :src="notice[key]" width="150px"/>
          </div>
          <div v-else>
            なし
          </div>
        </div>
        <div class="global-without-ignoring-br" v-else> {{ notice[key] }} </div>
      </td>
    </tr></tbody>
    </table>
    <div v-if="notice.loading"><div class="ui active inline loader"></div></div>

  </Wrapper>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Notices',
  components: {
    Wrapper: Wrapper,
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const limit = 30
    const columns = {
      'release_time': '公開日時',
      'body': '内容',
      'amount': '閲覧に必要なポイント数',
      'image_url': '画像',
      'id': '詳細'
    }
    return {
      columns: columns,
      limit: limit,
      orderBy: '-updated_at',
      sinceId: null,
    }
  },
  computed: mapState([
    'notice',
  ]),
  created () {
    this.pointId = this.$route.params.pointId
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.notice.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('notice/getNewNotices', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.notice.all) return
      this.$store.dispatch('notice/getNextNotices', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.notice.sinceId,
      })
    }
  },
}
</script>