<template>
  <div class="campaigns">
    <Wrapper :title="'キャンペーン一覧'" :hasInnerMenu="!!pointId">
      <div class="ui form">
      <div class="field">
        <select v-model="orderBy" class="ui fluid dropdown">
          <option value="-created_at">作成の新しい順</option>
          <option value="created_at">作成の古い順</option>
        </select>
      </div>
      <div class="field">
        <select v-model="ended" class="ui fluid dropdown">
          <option value="false">公開中</option>
          <option value="true">終了済み</option>
        </select>
      </div>
      <div class="field">
        <div class="ui teal basic button" @click="fetchNew">表示</div>
      </div>
      <div class="field">
        <DownloadCSV
          :resources="campaign.campaigns"
          :columns="columns"
          :fileName="'campaigns.csv'"
        />
      </div>
    </div>

    <table class="ui celled striped table">
    <thead><tr>
      <th v-for="value in columns" :key="value.id">
        {{ value }}
      </th>
    </tr></thead>
    <tbody><tr v-for="campaign in campaign.campaigns" :key="campaign.id">
      <td v-for="(value, key) in columns" :key="value.id">
        <div v-if="key==='id'">
          <div v-if="pointId">
            <router-link :to="{ path: '/points/' + pointId + '/campaigns/' + campaign[key] }" class="ui teal basic button">詳細</router-link>
          </div>
          <div v-else>
            <router-link :to="{ path: '/campaigns/' + campaign[key] }" class="ui teal basic button">詳細</router-link>
          </div>
        </div>
        <div v-else-if="key==='created_at' || key==='updated_at'">
          {{ campaign[key] | formatMinute }}
        </div>
        <div class="global-without-ignoring-br" v-else> {{ campaign[key] }} </div>
      </td>
    </tr></tbody>
    </table>
    <div v-if="campaign.loading"><div class="ui active inline loader"></div></div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DownloadCSV from '@/components/DownloadCSV.vue'
import Wrapper from '@/components/Wrapper.vue'

export default {
  name: 'Campaigns',
  components: {
    DownloadCSV: DownloadCSV,
    Wrapper,
  },
  data: function () {
    const limit = 30
    const columns = {
      'keyword': 'キーワード',
      'created_at': '設定日時',
      'amount': 'ポイント数',
      'distributed_amount': '配布済みポイント数',
      'total_amount_limit': '配布数上限',
      'type': '配布回数の制限',
      'id': '詳細'
    }
    return {
      pointId: null,
      columns: columns,
      limit: limit,
      orderBy: '-created_at',
      sinceId: null,
      ended: false,
    }
  },
  computed: mapState([
    'campaign',
  ]),
  created () {
    if (this.$route.params.pointId) {
      this.pointId = this.$route.params.pointId
    }
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.campaign.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('campaign/getNewCampaigns', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
        ended: this.ended,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.campaign.all) return
      this.$store.dispatch('campaign/getNextCampaigns', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
        ended: this.ended,
        since_id: this.campaign.sinceId,
      })
    }
  },
}
</script>