<template>
  <div class="login">
  </div>
</template>

<script>
export default {
  name: 'Login',
  created: function () {
    const store = localStorage
    const responseType = 'code'
    const clientId = process.env.VUE_APP_CLIENT_ID
    const redirectUri = process.env.VUE_APP_REDIRECT_URI
    const scope = 'api:access admin:access'
    const codeVerifier = this.randomString(128)
    const codeChallenge = codeVerifier;
    const codeChallengeMethod = 'plain'
    store.setItem('redirect_uri', redirectUri)
    store.setItem('scope', scope)
    store.setItem('code_verifier', codeVerifier)
    window.location.href = `${process.env.VUE_APP_API_BASE_URL}/auth/v0/authorize` +
      `?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=${codeChallengeMethod}`
  },
}
</script>
