<template>
  <div class="dashboard">
  <Wrapper
    :title="'ダッシュボード'"
    :hasInnerMenu="true"
  >
    <PointDetail />
  </Wrapper>
  </div>
</template>

<script>
import PointDetail from '@/components/PointDetail.vue'
import Wrapper from '@/components/Wrapper.vue'

export default {
  name: 'Dashboard',
  components: {
    PointDetail,
    Wrapper,
  },
}
</script>