<template>
  <div class="benefit-detail">
    <Wrapper :title="'特典詳細'">
      <div v-if="benefit.loading"><div class="ui active inline loader"></div></div>
      <div v-else>
        <div class="ui teal secondary pointing menu">
          <a @click="curItem=0" class="item" :class="{ active: curItem === 0 }">
            基本情報
          </a>
          <a v-if="!benefit.benefit.ended" @click="curItem=1" class="item" :class="{ active: curItem === 1 }">
            編集
          </a>
          <a v-if="!benefit.benefit.ended" @click="curItem=2" class="item" :class="{ active: curItem === 2 }">
            特典を終了する
          </a>
        </div>

        <div class="ui teal segment">
          <div v-if="curItem===0">
            <table class="ui celled striped table">
              <tbody>
                <tr v-for="(value, key) in contents" :key="value">
                  <td>{{ value }}</td>
                  <td>
                    <div v-if="isTime(key) && benefit.benefit[key]">
                      {{ benefit.benefit[key] | formatMinute }}
                    </div>
                    <div v-else-if="key==='state' && !benefit.benefit.is_visible">
                      {{ benefit.benefit[key] }} （限定公開）
                    </div>
                    <div v-else-if="key==='profile_image_url'">
                      <div v-if="isValidImage(benefit.benefit[key])">
                        <img :src="benefit.benefit[key]" width="50%"/>
                      </div>
                      <div v-else>
                        なし
                      </div>
                    </div>
                    <div class="global-without-ignoring-br" v-else> {{ benefit.benefit[key] }} </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="curItem===1">
            <EditBenefit
              :benefit="benefit.benefit"
            />
          </div>
          <div v-if="curItem===2">
            <EndBenefit
              :benefit="benefit.benefit"
              :onClick="endBenefitOnClick"
              :loading="ending"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import EditBenefit from '@/components/EditBenefit.vue'
import EndBenefit from '@/components/EndBenefit.vue'

export default {
  name: 'BenefitDetail',
  components: {
    Wrapper: Wrapper,
    EditBenefit: EditBenefit,
    EndBenefit: EndBenefit,
  },
  data: function () {
    const contents = {
      'name': 'タイトル',
      'description': '説明',
      'profile_image_url': '画像',
      'amount': 'ポイント数',
      'type': '形態',
      'state': '状態',
      'visible': '一般公開',
      'number_of_tickets': '引換件数',
      'number_of_used_tickets': '利用件数',
      'max_tickets': '最大チケット交換数',
      'start_time': '開始日',
      'expire_time': '特典交換期限',
      'ticket_expire_time': '特典チケット使用期限',
      'created_at': '作成日',
      'updated_at': '最終更新日',
    }
    return {
      benefitId: null,
      contents: contents,
      curItem: 0,
      ending: false,
    }
  },
  computed: {
    ...mapState([
      'benefit', 'point',
    ]),
  },
  created () {
    this.benefitId = this.$route.params.benefitId
    this.$store.dispatch('benefit/getBenefit', {
      id: this.benefitId,
    })
  },
  methods: {
    isValidImage (url) {
      return url.match(/(jpg|jpeg|png)$/)
    },
    async endBenefitOnClick() {
      if (!window.confirm(`特典 ${this.benefit.benefit.name} を終了します。よろしいですか？`)) {
        return
      }
      this.ending = true
      await this.$store.dispatch('benefit/endPublication', {
        id: this.benefitId,
      })
      alert('特典を終了しました')
      this.ending = false
      this.curItem = 0
    },
    isTime (key) {
      return key === 'created_at' ||
             key === 'updated_at' ||
             key === 'start_time' ||
             key === 'expire_time' ||
             key === 'ticket_expire_time'
    },
  },
}
</script>
