import api from '../../api'

const state = {
  notice: null,
  notices: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getNotice ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getNotice, payload)
    if (res !== null) {
      const news = res.data.news
      commit('setNotice', news)
    }
    commit('setLoading', false)
  },
  async getNewNotices ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getNotices, payload)
    if (res !== null) {
      const notices = res.data.news
      commit('setNotices', notices)
      if (notices.length > 0) {
        commit('setSinceId', notices[notices.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextNotices ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getNotices, payload)
    if (res !== null) {
      const notices = res.data.news
      commit('appendNotices', notices)
      if (notices.length > 0) {
        commit('setSinceId', notices[notices.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async createNotice ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.createNotice, payload)
    if (res !== null) {
      const notice = res.data.news
      commit('setNotice', notice)
    }
    commit('setLoading', false)
  },
  async deleteNotice ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.deleteNotice, payload)
    if (res !== null) {
      // nothing
      commit('setNullNotice');
    }
  },
}

const mutations = {
  setNotice(state, notice) {
    state.notice = modify(notice)
  },
  setNullNotice(state) {
    state.notice = null
  },
  setNotices (state, notices) {
    let nts = []
    notices.forEach(nt => {
      nts.push(modify(nt))
    })
    state.notices = nts
  },
  appendNotices (state, notices) {
    notices.forEach(notice => {
      state.notices.push(modify(notice))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

function modify(nt) {
  let n = nt
  if (nt.has_image) {
    n.image_url = nt.image.url
  }
  return n
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}