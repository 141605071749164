var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("このQRコードを再作成する")]),_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"ui form"},[_c('div',[_c('div',{staticClass:"two fields"},[_c('div',{staticClass:"field"},[_c('h4',[_vm._v("緯度"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"緯度","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.latitude),expression:"latitude"}],domProps:{"value":(_vm.latitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.latitude=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("経度"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"経度","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.longitude),expression:"longitude"}],domProps:{"value":(_vm.longitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.longitude=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('h4',[_vm._v("取得可能距離（単位は m です）"),_c('span',{staticClass:"required"},[_vm._v(" *")])]),_c('ValidationProvider',{attrs:{"name":"取得可能距離","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.capturable_distance),expression:"capturable_distance"}],domProps:{"value":(_vm.capturable_distance)},on:{"input":function($event){if($event.target.composing){ return; }_vm.capturable_distance=$event.target.value}}}),_c('span',{staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"ui teal basic button",class:{'loading': _vm.recreating},attrs:{"disabled":invalid},on:{"click":_vm.recreate}},[_vm._v(" 同じQRコードを再作成する ")])])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" このQRコードを終了させて同じQRコードを位置情報と取得可能距離のみ変更して再作成します"),_c('br'),_vm._v(" 既に終了しているQRコードの場合は再作成のみ行います"),_c('br'),_vm._v(" コミュニティストアのチェックインスタンプ作成のみに使ってください"),_c('br'),_vm._v(" （開始日時と終了日時はそれぞれ「現在」と「現在から 10 年後」になります ")])}]

export { render, staticRenderFns }