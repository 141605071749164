import api from '../../api'

const state = {
  holder: null,
  holders: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getHolder ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getHolder, payload)
    if (res !== null) {
      const holder = res.data.holder
      commit('setHolder', holder)
    }
    commit('setLoading', false)
  },
  async getNewHolders ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getHolders, payload)
    if (res !== null) {
      const holders = res.data.holders
      commit('setHolders', holders)
      if (holders.length > 0) {
        commit('setSinceId', holders[holders.length - 1].wallet)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextHolders ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getHolders, payload)
    if (res !== null) {
      const holders = res.data.holders
      commit('appendHolders', holders)
      if (holders.length > 0) {
        commit('setSinceId', holders[holders.length - 1].wallet)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
}

const mutations = {
  setHolder (state, holder) {
    state.holder = holder
  },
  setHolders (state, holders) {
    state.holders = holders
  },
  appendHolders (state, holders) {
    holders.forEach(holder => {
      state.holders.push(holder)
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
