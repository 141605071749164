import api from '../../api'

const state = {
  pendingUsers: [],
  loading: false,
}

const getters = {}

const actions = {
  async getNewPendingUsers ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getPendingUsers, payload)
    if (res !== null) {
      const pendingUsers = res.data.identification_infos
      commit('setPendingUsers', pendingUsers)
    }
    commit('setLoading', false)
  },
}

const mutations = {
  setPendingUsers (state, pendingUsers) {
    let pus = []
    pendingUsers.forEach(pu => {
      pus.push(modify(pu))
    })
    state.pendingUsers = pus
  },
  setLoading (state, loading) {
    state.loading = loading
  },
}

function modify(pu) {
  let p = pu
  p.document_front_image_id = pu.document_front_image.id
  return p
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}