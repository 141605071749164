<template>
  <div class="update-token-pricing-plan">
    <p>ポイントの配布可能なポイント数を変更します。</p>
    <p>
      現在配布可能なポイント数は <b style="font-size: 20px;">{{ point.point.amount }}</b> です。<br>
      現在の配布可能なポイント数（以降これを発行数と呼びます）よりも少ない発行数に変更する場合はその発行数の差分だけ現在の残高から引かれます。<br>
      その際に残高が足りない場合は残高は 0 と表示されますが実際には不足分が借金という形で記録されます。この状態で保有者からポイントが返還されると残高はそのままでまず借金が減り、借金が 0 になって初めて再度残高が戻ります。<br>
    </p>
    <ValidationObserver v-slot="{ invalid }">
    <div class="ui form">
      <div class="field">
        <label>新しく設定する配布可能なポイント数を入力してください</label>
        <ValidationProvider
          name="配布可能なポイント数"
          rules="required"
          v-slot="{ errors }"
        >
          <select v-model="amount" class="ui fluid dropdown">
            <option value="1000">1000</option>
            <option value="10000">10000</option>
            <option value="100000">100000</option>
            <option value="1000000">1000000</option>
          </select>
          <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <button
        class="ui teal basic button"
        @click="updateAmount"
        :disabled="invalid || !amountUpdated"
      >
        上記内容で変更する
      </button>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      amount: 1000,
    }
  },
  computed: {
    ...mapState([
      'point',
    ]),
    amountUpdated () {
      return Number(this.amount) !== this.point.point.amount
    },
  },
  methods: {
    async updateAmount () {
      if (!window.confirm(`${this.point.point.name} の配布可能なポイント数を ${this.point.point.amount} ptから ${this.amount} ptに変更します。よろしいですか？`)) {
        return
      }
      await this.$store.dispatch('point/updateAmount', {
        id: this.point.point.id,
        amount: Number(this.amount),
      })
      alert(`発行数を ${this.amount} ptに変更しました`)
      this.amount = 0
    }
  },
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>