<template>
  <div class="menu">

    <div id="drawer" class="ui vertical inverted sidebar menu left" :class="themeColor">
      <div class="item">
        <div class="header">
          <div class="ui logo icon image" style="width: 32px; background-color: white;">
            <img src="https://avatars0.githubusercontent.com/u/32948106?s=200&v=4">
          </div> &nbsp;
          <b>mint admin</b>
        </div>
        <div class="ui secondary vertical menu">
          <router-link class="item" onclick="$('#drawer').sidebar('toggle')" :to="'/points'">ポイント一覧</router-link>
          <router-link class="item" onclick="$('#drawer').sidebar('toggle')" :to="'/pending-users'">本人確認申請ユーザー一覧</router-link>
          <router-link class="item" onclick="$('#drawer').sidebar('toggle')" :to="'/campaigns'">キャンペーン一覧</router-link>
          <router-link class="item" onclick="$('#drawer').sidebar('toggle')" :to="'/users'">ユーザー一覧</router-link>
        </div>
      </div>
      <!--<div class="item">
        <div class="header">設定</div>
        <div class="menu">
          <a class="item">ポイント情報編集</a>
          <a class="item">プロフィール編集</a>
        </div>
      </div>
      -->
      <a class="item" @click="logout">ログアウト</a>
    </div>
    <div id="header" class="ui fixed inverted main menu" :class="themeColor">
      <div class="ui container">
        <a class="launch icon item" onclick="$('#drawer').sidebar('toggle')">
          <i class="content icon"></i>
        </a>
        <div class="item">{{ title }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Menu',
  data: function () {
    return {
      pointId: null,
    }
  },
  props: {
    title: String,
  },
  computed: {
    ...mapState([
    'point',
    ]),
    themeColor () {
      const color =
        process.env.VUE_APP_ENV === 'production' ? "teal" : "black"
      return color
    },
  },
  created: function () {
  },
  methods: {
    logout () {
      if (window.confirm('ログアウトしますか？')) {
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("access_token")
        this.$router.push('/login')
      }
    }
  },
}
</script>