<template>
  <div class="user-detail">
    <Wrapper :title="'ユーザー詳細'" :hasInnerMenu="false">
      <div v-if="user.loading || point.loading"><div class="ui active inline loader"></div></div>
      <div v-else>

        <div class="ui teal secondary pointing menu">
          <a @click="curItem=0" class="item" :class="{ active: curItem === 0 }">
            基本情報
          </a>
          <a @click="curItem=1" class="item" :class="{ active: curItem === 1 }">
            発行ポイント
          </a>
          <a @click="curItem=2" class="item" :class="{ active: curItem === 2 }">
            保有ウォレット一覧
          </a>
          <a @click="curItem=3" class="item" :class="{ active: curItem === 3 }">
            保有チケット一覧
          </a>
          <a @click="curItem=4" class="item" :class="{ active: curItem === 4 }">
            アカウント削除
          </a>
        </div>

        <div class="ui teal segment">
          <div v-if="curItem===0">
            <div class="item">
              <table class="ui celled striped table">
                <tbody>
                  <tr v-for="(value, key) in contents" :key="value">
                    <td>{{ value }}</td>
                    <td>
                      <div v-if="key==='email_authenticated_at'">
                        {{ user.user[key] | formatDate }}
                      </div>
                      <div v-else-if="key==='meta'">
                        <div v-if="user.user[key] && Object.keys(user.user[key]).length > 0">
                          <table class="ui celled striped table">
                            <thead><tr>
                              <th>属性</th>
                              <th>値</th>
                            </tr></thead>
                            <tbody><tr v-for="(invalue, inkey) in user.user[key]" :key="`${inkey}`">
                              <td>{{ inkey }}</td>
                              <td>{{ invalue }}</td>
                            </tr></tbody>
                          </table>
                        </div>
                        <div v-else>
                          なし
                        </div>
                      </div>
                      <div v-else-if="key==='twitter_url'">
                        <div v-if="user.user[key]">
                          <a class="ui teal basic button" target="_blank" :href="user.user[key]">ツイッターアカウント</a>
                        </div>
                        <div v-else>
                          未連携
                        </div>
                      </div>
                      <div v-else-if="key==='phone_number'&&user.user[key]">
                        {{ user.user[key].replace("+81", "0") }}
                      </div>
                      <div v-else-if="key==='id' || key==='screen_name' || key==='email'">
                        <CopiableContent
                          :text="user.user[key]"
                          :name="key"
                        />
                      </div>
                      <div v-else>
                        {{ user.user[key] }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="curItem===1">
            <div class="item">
              <div v-if="point.point">
                <router-link :to="{ path: '/points/' + point.point.id }" class="ui teal basic button">ポイントダッシュボード</router-link>
                <br>
                <PointDetail />
              </div>
              <div v-else>
                なし
              </div>
            </div>
          </div>
          <div v-if="curItem===2">
            <div class="item">
              <Wallets
                :userId="userId"
              />
            </div>
          </div>
          <div v-if="curItem===3">
            <div class="item">
              <Tickets
                :userId="userId"
              />
            </div>
          </div>
          <div v-if="curItem===4">
            <div class="item">
              <button @click="deleteUser" class="ui teal basic button">アカウントを削除する</button>
            </div>
          </div>
        </div>

      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import Wallets from '@/components/Wallets.vue'
import Tickets from '@/components/Tickets.vue'
import PointDetail from '@/components/PointDetail.vue'
import CopiableContent from '@/components/CopiableContent.vue'

export default {
  name: 'UserDetail',
  components: {
    Wrapper: Wrapper,
    Wallets: Wallets,
    Tickets: Tickets,
    PointDetail: PointDetail,
    CopiableContent,
  },
  data: function () {
    const contents = {
      'id': 'ユーザー ID',
      'email': 'メールアドレス',
      'email_authenticated_at': 'メール認証完了時間',
      'name': 'ユーザー名',
      'screen_name': 'スクリーンネーム',
      'phone_number': '電話番号',
      'twitter_url': 'ツイッター連携',
      'meta': '詳細情報',
    }
    return {
      userId: null,
      contents: contents,
      curItem: 0,
    }
  },
  computed: mapState([
    'user', 'point',
  ]),
  async created () {
    this.userId = this.$route.params.userId
    this.$store.dispatch('user/getUser', {
      id: this.userId,
    })
    await this.$store.dispatch('point/getPointByUserId', {
      user_id: this.userId,
    })
  },
  methods: {
    async deleteUser () {
      let e = window.prompt('該当ユーザーを本当に削除しますか？確認のため該当ユーザーのメールアドレスを入力してください')
      if (e !== this.user.user.email) {
        window.alert('メールアドレスが一致しません。再度ご確認ください')
        return
      }
      if (!window.confirm(`メールアドレス: ${this.user.user.email}
ユーザー名: ${this.user.user.name}
のユーザーを削除します。この操作はもとに戻せません。よろしいですか？`)) {
        return
      }
      await this.$store.dispatch('user/delete', {
        id: this.userId,
      })
      alert('ユーザーの削除が完了しました')
    }
  },
}
</script>