<template>
  <div class="wrapper">
    <Menu />
    <div v-if="hasInnerMenu">
      <div class="flex">
        <InnerMenu :title="title" />
        <Container
          :isSmall="true"
        >
          <slot />
          <Footer />
        </Container>
      </div>
    </div>
    <div v-else>
      <Container>
        <slot />
      </Container>
    </div>
  </div>
</template>
<script>
import Menu from '@/components/Menu.vue'
import InnerMenu from '@/components/InnerMenu.vue'
import Container from '@/components/Container.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu,
    InnerMenu,
    Container,
    Footer,
  },
  props: {
    title: String,
    hasInnerMenu: {
      type: Boolean,
      default: true,
    },
  }
}
</script>
<style scoped>
.flex {
  display: flex;
}
</style>