import axios from 'axios'
import router from './router'

const IS_PRODUCTION = (process.env.VUE_APP_ENV === 'production')

// FIXME: API が二種類以上走るときにリフレッシュトークンが二回以上走るので対策
let REFRESHING = false

async function getWithAuth(url) {
    const res = await axios.get(url, {
      headers: { authorization: 'Bearer ' + localStorage.getItem('access_token') }
    }).catch(err => {
      return { err }
    })
    if (!IS_PRODUCTION) console.log(res)
    return res
}

async function postWithAuth(url, payload) {
    const res = await axios.post(url, payload, {
      headers: { authorization: 'Bearer ' + localStorage.getItem('access_token') }
    }).catch(err => {
      return { err }
    })
    if (!IS_PRODUCTION) console.log(res)
    return res
}

async function postFormDataWithAuth(url, payload) {
    const res = await axios.post(url, payload, {
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'content-type': 'multipart/form-data',
      }
    }).catch(err => {
      return { err }
    })
    if (!IS_PRODUCTION) console.log(res)
    return res
}

export default {
  sleep (time) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, time)
    })
  },

  async tryAndIfTokenExpiredTryAgain(func, payload) {
    for (let i = 0; i < 2; i ++) {
      const res = await func(payload)
      if (res.err) {
        if (i == 0) {
          if (REFRESHING) {
            await this.sleep(500) // 別の API call で refresh token が走っていることを想定する
            continue
          }
          REFRESHING = true
          const refreshRes = await this.refreshToken({
            grant_type: "refresh_token",
            client_id: process.env.VUE_APP_CLIENT_ID,
            refresh_token: localStorage.getItem('refresh_token'),
            code_verifier: localStorage.getItem('code_verifier'),
          })
          REFRESHING = false
          if (refreshRes.err) {
            alert('認証エラーが発生しました。再度ログインしてください。')
            router.push('/login')
            return null
          } else {
            localStorage.setItem('access_token', refreshRes.data.access_token)
            localStorage.setItem('refresh_token', refreshRes.data.refresh_token)
            continue
          }
        /*
        認証エラーかどうかを判定したいが，現時点でエラーメッセージから判定するすべがない，API の修正待ち
        } else if (res.err.response.data.title === 'jwt_security_error') {
          alert('認証エラーが発生しました。再度ログインしてください。')
          router.push('/login')
          return null
        } else {
          alert(`内部エラーが発生しました: ${res.err.response.data.user_msg}`)
          return null
        }
        */
        } else {
          alert(`内部エラーが発生しました: ${res.err.response.data.user_msg}`)
          router.push('/login')
          return null
        }
      }
      return res
    }
  },

  async refreshToken(payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/auth/v0/token`
    const res = await axios.post(url, payload).catch(err => {
      return { err }
    })
    return res
  },

  async getAccessToken (payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/auth/v0/token`
    const res = await axios.post(url, payload, {
      headers: { 'Accept': 'application/json' }
    }).catch(err => {
      return { err }
    })
    return res
  },

  async getPoint (payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async getPointByUserId (payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/list_all?user_id=${payload.user_id}&as_organizer=true`
    const res = await getWithAuth(url)
    return res
  },

  async getPoints (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/list_all?&limit=${payload.limit}&order_by=${payload.order_by}&keyword=${encodeURIComponent(payload.keyword)}`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getHolder (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/holders/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async getHolders (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/holders?token=${payload.point_id}&limit=${payload.limit}&order_by=${payload.order_by}`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getNotice (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/news_templates/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async getNotices (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/news_templates?token=${payload.point_id}&limit=${payload.limit}&order_by=${payload.order_by}`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async createNotice (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/news/create`
    const res = await postWithAuth(url, payload)
    return res
  },

  async deleteNotice (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/news/${payload.id}/delete`
    const res = await postWithAuth(url, payload)
    return res
  },

  async getTransaction (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/journals/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async getTransactions (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/journals/list_all?&limit=${payload.limit}&order_by=${payload.order_by}&as_organizer=true`
    if (payload.stamp_id) {
      url = `${url}&stamp=${payload.stamp_id}`
    } else {
      url = `${url}&token=${payload.point_id}`
      if (payload.wallet_id) {
        url = `${url}&wallet=${payload.wallet_id}`
      }
    }
    if (payload.oldest) {
      let o = payload.oldest + ':00%2b09:00'
      url = `${url}&oldest=${o}`
    }
    if (payload.latest) {
      let l = payload.latest + ':00%2b09:00'
      url = `${url}&latest=${l}`
    }
    if (payload.route) {
      url = `${url}&route=${payload.route}`
    }
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getBenefit (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/benefits/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async createBenefit (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/benefits/create`
    const res = await postWithAuth(url, payload)
    return res
  },

  async endBenefitPublication (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/benefits/${payload.id}/end_publication`
    const res = await postWithAuth(url, payload)
    return res
  },

  async updateBenefit (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/benefits/${payload.id}/update`
    const res = await postWithAuth(url, payload)
    return res
  },

  async getBenefits (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/benefits/list_for_admin?org=${payload.point_id}&limit=${payload.limit}&order_by=${payload.order_by}&ended=${payload.ended}&started=${payload.started}&include_invisible=true`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getCampaign (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/snspromo/v0/campaigns/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async getCampaigns (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/snspromo/v0/campaigns?limit=${payload.limit}&order_by=${payload.order_by}&ended=${payload.ended}`
    if (payload.point_id) {
      url = `${url}&org=${payload.point_id}`
    }
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getTweets (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/snspromo/v0/campaign_tweets?campaign=${payload.campaign_id}&limit=${payload.limit}&order_by=${payload.order_by}`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getStamp (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/stamps/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async getStamps (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/stamps?token=${payload.point_id}&limit=${payload.limit}&order_by=${payload.order_by}&include_expired=${payload.include_expired}`
    if (payload.latitude !== null && payload.longitute !== null) {
      url = `${url}&latitude=${payload.latitude}&longitude=${payload.longitude}`
    }
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async createStamp (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/stamps/create`
    const res = await postWithAuth(url, payload)
    return res
  },

  async createImage(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/image/v0/images/create`
    const res = await postWithAuth(url, payload)
    return res
  },

  async uploadImage(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/image/v0/images/upload`
    const res = await postFormDataWithAuth(url, payload)
    return res
  },

  async send(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/${payload.point_id}/send`
    const res = await postWithAuth(url, payload)
    return res
  },

  async retrieve(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/${payload.id}/retrieve`
    const res = await postWithAuth(url, payload)
    return res
  },

  /* eslint no-unused-vars: 0 */
  async getPendingUsers (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/user/v0/identification_info/list?offset=0&limit=500`
    const res = await getWithAuth(url)
    return res
  },

  async getUser (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/user/v0/users/${payload.id}?fields=meta`
    const res = await getWithAuth(url)
    return res
  },

  async getUsers (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/user/v0/users?limit=${payload.limit}&order_by=${payload.order_by}&keyword=${encodeURIComponent(payload.keyword)}&type=${payload.type}`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async getWallets (payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/wallets/list_for_admin?user_id=${payload.user_id}&limit=${payload.limit}`
    const res = await getWithAuth(url)
    return res
  },

  async getTickets (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/tickets/list_all?user_id=${payload.user_id}&limit=${payload.limit}&order_by=${payload.order_by}`
    if (payload.since_id) {
      url = `${url}&since_id=${payload.since_id}`
    }
    const res = await getWithAuth(url)
    return res
  },

  async giveTicket (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/benefit/v0/tickets/${payload.id}/give`
    const res = await postWithAuth(url, payload)
    return res
  },

  async deleteUser (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/user/v0/users/${payload.id}/delete`
    const res = await postWithAuth(url, payload)
    return res
  },

  async collect(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/${payload.id}/collect_all_distributed_amount`
    const res = await postWithAuth(url, payload)
    return res
  },

  async updateTokenPricingPlan(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/${payload.id}/update_pricing_plan`
    const res = await postWithAuth(url, payload)
    return res
  },

  async updateAmount(payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/tokens/${payload.id}/update_amount`
    const res = await postWithAuth(url, payload)
    return res
  },

  async endStamp (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/stamps/${payload.id}/end`
    const res = await postWithAuth(url, payload)
    return res
  },

  async getPortal (payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/portal/v0/portals/${payload.id}`
    const res = await getWithAuth(url)
    return res
  },

  async updatePortal (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/portal/v0/portals/update`
    const res = await postWithAuth(url, payload)
    return res
  },

  async listOidc (payload) {
    const url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/oidc?token_id=${payload.token_id}`
    const res = await getWithAuth(url)
    return res
  },

  async createOidc (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/oidc/create`
    const res = await postWithAuth(url, payload)
    return res
  },

  async updateOidc (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/oidc/${payload.oidc_id}/update`
    const res = await postWithAuth(url, payload)
    return res
  },

  async deleteOidc (payload) {
    let url = `${process.env.VUE_APP_API_BASE_URL}/token/v0/oidc/${payload.oidc_id}/delete`
    const res = await postWithAuth(url, payload)
    return res
  },
}
