<template>
  <div class="transaction-detail">
    <Wrapper :title="'送受信履歴詳細'">
      <div v-if="transaction.loading"><div class="ui active inline loader"></div></div>
      <div v-else>
        <table class="ui celled striped table">
          <tbody>
            <tr v-for="(value, key) in contents" :key="value">
              <td>{{ value }}</td>
              <td>
                <div v-if="key==='created_at' || key==='updated_at'">
                  {{ transaction.transaction[key] | formatTime }}
                </div>
                <div v-else-if="key==='meta'">
                  <div v-if="transaction.transaction[key] && Object.keys(transaction.transaction[key]).length > 0">
                    <table class="ui celled striped table">
                      <thead><tr>
                        <th>属性</th>
                        <th>値</th>
                      </tr></thead>
                      <tbody><tr v-for="(value, key) in transaction.transaction[key]" :key="value">
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr></tbody>
                    </table>
                  </div>
                  <div v-else>
                    なし
                  </div>
                </div>
                <div class="global-without-ignoring-br" v-else> {{ transaction.transaction[key] }} </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'

export default {
  name: 'TransactionDetail',
  components: {
    Wrapper: Wrapper,
  },
  data: function () {
    const contents = {
      'amount': 'ポイント数',
      'created_at': '送信日時',
      'from_name': '送信者',
      'to_name': '受信者',
      'message': 'メッセージ',
      'meta': '詳細情報',
    }
    return {
      transactionId: null,
      contents: contents,
    }
  },
  computed: mapState([
    'transaction',
  ]),
  created () {
    this.transactionId = this.$route.params.transactionId
    this.$store.dispatch('transaction/getTransaction', {
      id: this.transactionId,
    })
  }
}
</script>