<template>
  <div class="stamps">

  <Wrapper :title="'QRコード一覧'">
    <h3>QRコード一覧</h3>
    <ValidationObserver v-slot="{ invalid }">
    <div class="ui form">
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" v-model="includeExpired">
          <label>終了済みの QR コードを含める</label>
        </div>
      </div>
      <div class="field">
        <select v-model="orderBy" class="ui fluid dropdown">
          <option value="-created_at">作成の新しい順</option>
          <option value="created_at">作成の古い順</option>
          <option value="distance">近い順(位置情報スタンプのみ表示)</option>
          <option value="-distance">遠い順(位置情報スタンプのみ表示)</option>
        </select>
      </div>
      <div class="two field" v-if="orderBy==='distance' || orderBy==='-distance'">
        <div class="field">
          <h4>緯度（省略した場合 0.0）</h4>
          <ValidationProvider
            name="緯度"
            rules="decimal"
            v-slot="{ errors }"
          >
            <input v-model="latitude"/>
            <span class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="field">
          <h4>経度（省略した場合 0.0）</h4>
          <ValidationProvider
            name="経度"
            rules="decimal"
            v-slot="{ errors }"
          >
            <input v-model="longitude"/>
            <span class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="field">
        <button :disabled="invalid" class="ui teal basic button" @click="fetchNew">表示</button>
      </div>
      <div class="field">
        <DownloadCSV
          :resources="stamp.stamps"
          :columns="dynamicColumns"
          :fileName="'stamps.csv'"
        />
      </div>
    </div>
    </ValidationObserver>

    <table class="ui celled striped table">
    <thead>
    <tr>
      <th v-for="(value, key) in dynamicColumns" :key="value.id">
        <div v-if="key==='id'">
          詳細
        </div>
        <div v-else>
          {{ value }}
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="stamp in stamp.stamps" :key="stamp.id">
      <td v-for="(value, key) in dynamicColumns" :key="value.id">
        <div v-if="key==='id'">
          <router-link :to="{ path: '/points/' + pointId + '/stamps/' + stamp[key] }" class="ui teal basic button">詳細</router-link>
        </div>
        <div v-else-if="key==='expiration_time' || key==='release_time'">
          {{ stamp[key] | formatMinute }}
        </div>
        <div v-else>
          {{ stamp[key] }}
        </div>
      </td>
    </tr>
    </tbody>
    </table>

    <div v-if="stamp.loading"><div class="ui active inline loader"></div></div>
  </Wrapper>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  components: {
    Wrapper: Wrapper,
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const num = 30
    return {
      pointId: '',
      orderBy: '-created_at',
      limit: num,
      latitude: null,
      longitude: null,
      isDistanceTable: false,
      includeExpired: false,
    }
  },
  computed: { 
    ...mapState(['stamp']),
    dynamicColumns () {
      let columns = {
        'amount': 'ポイント数',
        'type': '種類',
        'total_holder_limit': '最大配布人数',
        'release_time': '開始日時',
        'expiration_time': '終了日時',
        'state': '状態'
      }
      if (this.isDistanceTable) {
        columns.latitude = '緯度'
        columns.longitude = '経度'
        columns.distance = '距離'
      }
      columns.id = 'ID'
      return columns
    }
  },
  created () {
    this.pointId = this.$route.params.pointId
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.stamp.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      if (this.orderBy === 'distance' || this.orderBy === '-distance') {
        this.isDistanceTable = true
        let lat = Number(this.latitude)
        let lon = Number(this.longitude)
        if (this.latitude === null) lat = 0.0
        if (this.longitude === null) lon = 0.0
        this.$store.dispatch('stamp/getNewStamps', {
          point_id: this.pointId,
          limit: 500,
          order_by: this.orderBy,
          latitude: lat,
          longitude: lon,
          include_expired: this.includeExpired,
        })
      } else {
        this.isDistanceTable = false
        this.$store.dispatch('stamp/getNewStamps', {
          point_id: this.pointId,
          limit: this.limit,
          order_by: this.orderBy,
          latitude: null,
          longitude: null,
          include_expired: this.includeExpired,
        })
      }
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.stamp.all) return
      if (this.isDistanceTable) return // pagination doesn't work
      this.$store.dispatch('stamp/getNextStamps', {
        point_id: this.pointId,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.stamp.sinceId,
        latitude: null,
        longitude: null,
        include_expired: this.includeExpired,
      })
    }
  },
}
</script>
<style scoped>
.err {
  color: red;
}
</style>