import api from '../../api'

const state = {
  image: null,
}

const getters = {}

const actions = {
  async createImage ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.createImage, payload)
    if (res !== null) {
      const image = res.data.image
      commit('setImage', image)
    }
  },
  async uploadImage ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.uploadImage, payload)
    if (res !== null) {
      const image = res.data.image
      commit('setImage', image)
    }
  },
}

const mutations = {
  setImage (state, image) {
    state.image = image
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
