<template>
  <div class="campaign-detail">
    <Wrapper :title="'キャンペーン詳細'" :hasInnerMenu="!!pointId">
      <div v-if="campaign.loading"><div class="ui active inline loader"></div></div>
      <div v-else>
        <table class="ui celled striped table">
          <tbody>
            <tr v-for="(value, key) in contents" :key="value">
              <td>{{ value }}</td>
              <td>
                <div v-if="key==='created_at' || key==='updated_at'">
                  {{ campaign.campaign[key] | formatTime }}
                </div>
                <div class="global-without-ignoring-br" v-else> {{ campaign.campaign[key] }} </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>配布済みツイート一覧</h4>
        <Tweets
          :campaignId="campaignId"
          :amount="campaign.campaign.amount"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import Tweets from '@/components/Tweets.vue'

export default {
  name: 'CampaignDetail',
  components: {
    Wrapper: Wrapper,
    Tweets: Tweets,
  },
  data: function () {
    const contents = {
      'keyword': 'キーワード',
      'created_at': '作成日',
      'amount': 'ポイント数',
      'distributed_amount': '配布済みポイント数',
      'total_amount_limit': '配布数上限',
      'type': '配布回数の制限',
    }
    return {
      campaignId: null,
      pointId: null,
      contents: contents,
    }
  },
  computed: mapState([
    'campaign',
  ]),
  created () {
    this.campaignId = this.$route.params.campaignId
    if (this.$route.params.pointId) {
      this.pointId = this.$route.params.pointId
    }
    this.$store.dispatch('campaign/getCampaign', {
      id: this.campaignId,
    })
  }
}
</script>