<template>
  <!-- verify -->
  <td class="ui center aligned">
    <div v-if="!rejected" class="inline">
      <button class="ui teal basic button" v-if="!verified && !verifying && !rejecting" @click="verify">承認</button>
      <div v-else-if="verifying"><div class="ui active inline loader"></div></div>
      <div v-else-if="verified">OK</div>
      <div v-else-if="verifyingError">{{ error }}</div>
    </div>
    <!-- reject -->
    <div v-if="!verified" class="inline">
      <button class="ui teal basic button" v-if="!rejected && !verifying && !rejecting" @click="reject">拒否</button>
      <div v-else-if="rejecting"><div class="ui active inline loader"></div></div>
      <div v-else-if="rejected">OK</div>
      <div v-else-if="rejectingError">{{ error }}</div>
    </div>
  </td>
</template>

<script>
import axios from 'axios'

export default {
  name: 'VerifyButton',
  props: {
    userId: {
      required: true
    },
    userRealName: {
      required: true
    }
  },
  data () {
    return {
      verifying: false,
      verified: false,
      verifyingError: false,

      rejecting: false,
      rejected: false,
      rejectingError: false
    }
  },
  methods: {
    verify: function () {
      this.verifying = true
      axios.post(process.env.VUE_APP_API_BASE_URL + '/user/v0/identification_info/' + this.userId + '/verify', {
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.access_token
        }
      /* eslint no-unused-vars: 0 */
      }).then(response => {
        this.verifying = false
        this.verified = true
      }).catch(error => {
        this.verifying = false
        this.verifyingError = error
      })
    },
    reject: function () {
      var res = window.confirm('該当ユーザー(本名:' + this.userRealName + ')の本人確認申請を拒否します。よろしいですか？')
      if (!res) {
        return
      }
      this.rejecting = true
      axios.post(process.env.VUE_APP_API_BASE_URL + '/user/v0/identification_info/' + this.userId + '/reject', {
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.access_token
        }
      /* eslint no-unused-vars: 0 */
      }).then(response => {
        this.rejecting = false
        this.rejected = true
      }).catch(error => {
        this.rejecting = false
        this.rejectingError = error
      })
    }
  }
}
</script>

<style scoped>
.inline {
  display: inline-block;
}
</style>