import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './util/filters'
import mixin from './util/mixin'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja'
import ProgressBar from 'vuejs-progress-bar'

Vue.config.productionTip = false

// global filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

// global mixins
Vue.mixin(mixin)

// import validator
for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: ja.messages[rule],
  })
}
extend('decimal', { // no default implementation on 3.x
  validate: value => /^\d+(?:\.\d+)?$/.test(value),
  message: '{_field_}は正の数値のみ使用できます'
})
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// progress bar
Vue.use(ProgressBar)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
