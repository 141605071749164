<template>
  <div class="point-transactions">
  <Wrapper :title="'送受信履歴'">
    <Transactions />
  </Wrapper>

  </div>
</template>

<script>
import Wrapper from '@/components/Wrapper.vue'
import Transactions from '@/components/Transactions.vue'

export default {
  name: 'PointTransactions',
  components: {
    Wrapper: Wrapper,
    Transactions: Transactions,
  },
  data: function () {
    return {
      pointId: null,
    }
  },
}
</script>