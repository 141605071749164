<template>
  <div class="send-point">

  <ValidationObserver v-slot="{ invalid }">
  <div class="ui form">
    <div class="field">
      <h4>ポイント数<span class="required"> *</span></h4>
      <ValidationProvider
        name="ポイント数"
        rules="required|numeric|between:1,1000"
        v-slot="{ errors }"
      >
        <input v-model="amount" />
        <span class="err">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <div class="field">
      <h4>受信者へのメッセージ {{ message ? message.length : 0 }}/600</h4>
      <ValidationProvider
        name="受信者へのメッセージ"
        rules="max:600"
        v-slot="{ errors }"
      >
        <textarea rows="4" cols="50" v-model="message" />
        <span class="err">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <button :disabled="invalid" class="ui teal basic button" @click="send">ポイントを送る</button>
  </div>
  </ValidationObserver>

  </div>
</template>

<script>
export default {
  data () {
    return {
      amount: 1,
      message: '',
      pointId: null,
    }
  },
  props: {
    screenName: {
      type: String,
      required: true,
    },
  },
  created () {
    this.pointId = this.$route.params.pointId
  },
  methods: {
    async send () {
      if (!window.confirm(`この保有者に${this.amount}pt送ります。よろしいですか？`)) {
        return
      }
      let msg = this.message === '' ? null : this.message
      await this.$store.dispatch('point/send', {
        as_organizer: true,
        point_id: this.pointId,
        amount: Number(this.amount),
        to_screen_name: this.screenName,
        message: msg,
      })
      alert('ポイントを送りました')
    }
  }
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>
