<template>
  <div class="create-stamps">

  <Wrapper :title="'QRコード作成'">
    <h3>QRコードを新規作成する</h3>
    <p>
      複数人が読み取れるQRコードを発行できます。<br>
      QRコードの有効期限を指定しない場合は，発行日から10年間です。
    </p>
    <ValidationObserver v-slot="{ invalid }">
    <div class="ui form">
      <div class="field">
        <h4>QRコードの種類<span class="required"> *</span></h4>
        <select v-model="spec" class="ui dropdown">
          <option value="default">1回のみ</option>
          <option value="once_daily">1日1回</option>
          <option value="community_store_checkin">コミュニティストアチェックイン</option>
          <option value="community_store_intro">コミュニティストアイントロ</option>
          <option value="no_limit">読み取り回数無制限</option>
        </select>
      </div>
      <div class="field">
        <h4>ポイント数<span class="required"> *</span></h4>
        <ValidationProvider
          name="ポイント数"
          rules="required|numeric|between:1,100"
          v-slot="{ errors }"
        >
          <input v-model="amount" />
          <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="field">
        <h4>名前（ユーザーには表示されません） {{ name ? name.length : 0 }} /100</h4>
        <ValidationProvider
          name="名前"
          rules="max:100"
          v-slot="{ errors }"
        >
          <input v-model="name" />
          <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="field">
        <h4>説明（ユーザーには表示されません） {{ description ? description.length : 0 }} /100</h4>
        <ValidationProvider
          name="説明"
          rules="max:600"
          v-slot="{ errors }"
        >
          <textarea rows="4" cols="50" v-model="description" />
          <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="two fields">
        <div class="field">
          <h4>開始日時（現在より後を指定。指定しない場合は現在）</h4>
          <input v-model="release_time" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
        </div>
        <div class="field">
          <h4>終了日時 （開始日時よりも後を指定。指定しない場合は現在から10年後）</h4>
          <input v-model="expiration_time" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
        </div>
      </div>
      <div class="field">
        <h4>受信者へのメッセージ {{ message ? message.length : 0 }}/600</h4>
        <ValidationProvider
          name="受信者へのメッセージ"
          rules="max:600"
          v-slot="{ errors }"
        >
          <textarea rows="4" cols="50" v-model="message" />
          <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" @change="initLocation" v-model="isLocation">
          <label>取得可能な位置情報を指定する</label>
        </div>
      </div>
      <div v-if="isLocation">
        <div class="two fields">
          <div class="field">
            <h4>緯度<span class="required"> *</span></h4>
            <ValidationProvider
              name="緯度"
              rules="decimal"
              v-slot="{ errors }"
            >
              <input v-model="latitude"/>
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="field">
            <h4>経度<span class="required"> *</span></h4>
            <ValidationProvider
              name="経度"
              rules="decimal"
              v-slot="{ errors }"
            >
              <input v-model="longitude"/>
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="field">
          <h4>取得可能距離（設定しない場合はデフォルトで 30m になります。単位は m です）</h4>
          <ValidationProvider
            name="取得可能距離"
            rules="decimal"
            v-slot="{ errors }"
          >
            <input v-model="capturable_distance"/>
            <span class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <br>
      <button :disabled="invalid" class="ui teal basic button" @click="create">作成</button>
    </div>
    </ValidationObserver>
  </Wrapper>

  </div>
</template>

<script>
import Wrapper from '@/components/Wrapper.vue'

export default {
  components: {
    Wrapper: Wrapper,
  },
  data: function () {
    return {
      pointId: '',
      amount: 1,
      name: null,
      description: null,
      latitude: null,
      longitude: null,
      spec: 'default',
      capturable_distance: null,
      message: null,
      isLocation: false,
      release_time: null,
      expiration_time: null,
    }
  },
  created: function () {
    this.pointId = this.$route.params.pointId
  },
  methods: {
    initLocation () {
      this.longitude = null
      this.latitude = null
      this.capturable_distance = null
    },
    async create () {
      let amnt = Number(this.amount)
      let lat = Number(this.latitude)
      let lon = Number(this.longitude)
      let dist = Number(this.capturable_distance)
      let rt = this.release_time + ':00+09:00'
      let et = this.expiration_time + ':00+09:00'
      if (this.release_time === null) rt = null
      if (this.expiration_time === null) et = null
      if (et === null) {
        let exp = new Date()
        exp.setDate(exp.getDate() + 365 * 10 - 1)
        et = this.formatTimePost(exp)
      }
      if (this.latitude === null || this.latitude === '') lat = null
      if (this.longitude === null || this.longitude === '') lon = null
      if (this.capturable_distance === null || this.capturable_distance === '') dist = null
      if (lat === null || lon === null) {
        if (lat !== null || lon !== null) {
          alert('位置を正しく指定してください')
          return
        }
        if (dist !== null) {
          alert('位置を正しく指定した上で取得可能距離を指定してください')
          return
        }
      }
      dist = dist / 1000.0
      await this.$store.dispatch('stamp/createStamp', {
        token: this.pointId,
        amount: amnt,
        name: this.name,
        description: this.description,
        total_amount_limit: 0,
        total_holder_limit: 0,
        spec: this.spec,
        release_time: rt,
        expiration_time: et,
        latitude: lat,
        longitude: lon,
        capturable_distance: dist,
        message: this.message
      })
      alert('QRコードが作成されました。QRコード一覧よりご確認ください') // FIXME error handling
      this.amount = 1
      this.name = null
      this.description = null
      this.spec = 'default'
      this.latitude = null
      this.longitude = null
      this.capturable_distance = null
      this.message = null
      this.release_time = null
      this.expiration_time = null
    },
  },
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>