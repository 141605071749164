import moment from 'moment'

export function formatTime (date) {
  return moment(date).format('YYYY/MM/DD HH:mm:ss')
}

export function formatDate (date) {
  return moment(date).format('YYYY/MM/DD')
}

export function formatMinute (date) {
  return moment(date).format('YYYY/MM/DD HH:mm')
}