<template>
  <div class="update-token-pricing-plan">
    <p>ポイントの課金プランを変更します。</p>
    <p>現在のプランは <b style="font-size: 20px;">{{ point.point.pricing_plan }}</b> です。</p>
    <ValidationObserver v-slot="{ invalid }">
    <div class="ui form">
      <div class="field">
        <label>新しいプランを選択してください</label>
        <ValidationProvider
          name="プラン"
          rules="required"
          v-slot="{ errors }"
        >
        <select v-model="plan" class="ui fluid dropdown">
          <option value="free">free</option>
          <option value="plus">plus</option>
          <option value="pro">pro</option>
          <option value="enterprise">enterprise</option>
        </select>
        <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <button
        class="ui teal basic button"
        @click="updateTokenPricingPlan"
        :disabled="invalid"
      >
        上記内容で変更する
      </button>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      plan: '',
    }
  },
  computed: {
    ...mapState([
      'point',
    ]),
  },
  methods: {
    async updateTokenPricingPlan () {
      if (!window.confirm(`${this.point.point.name} の課金プランを ${this.plan} に変更します。よろしいですか？`)) {
        return
      }
      await this.$store.dispatch('point/updateTokenPricingPlan', {
        id: this.point.point.id,
        pricing_plan: this.plan,
      })
      alert(`課金プランを ${this.plan} に変更しました`)
    }
  },
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>