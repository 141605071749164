<template>
  <div class="points">

  <Wrapper :title="'ポイント一覧'" :hasInnerMenu="false">
    <div class="ui form">
      <div class="field"><input v-model="keyword" placeholder="ポイント名検索"/></div>
      <div class="field">
        <select v-model="orderBy" class="ui fluid dropdown">
          <option value="-updated_at">利用日の新しい順</option>
          <option value="updated_at">利用日の古い順</option>
          <option value="-created_at">初回獲得日の新しい順</option>
          <option value="created_at">初回獲得日の古い順</option>
        </select>
      </div>
      <div class="field">
        <div class="ui teal basic button" @click="fetchNew">表示</div>
      </div>
      <div class="field">
        <DownloadCSV
          :resources="point.points"
          :columns="columns"
          :fileName="'points.csv'"
        />
      </div>
    </div>

    <table class="ui celled striped table">
    <thead><tr>
      <th v-for="value in columns" :key="value.id">
        {{ value }}
      </th>
    </tr></thead>
    <tbody><tr v-for="point in point.points" :key="point.id">
      <td v-for="(value, key) in columns" :key="value.id">
        <div v-if="key==='created_at' || key==='last_transacted_at' || key==='owner_last_signed_in_at'">
          {{ point[key] | formatDate }}
        </div>
        <div v-else-if="key==='id'">
          <router-link :to="{ path: '/points/' + point[key] }" class="ui teal basic button">詳細</router-link>
        </div>
        <div v-else>
          {{ point[key] }}
        </div>
      </td>
    </tr></tbody>
    </table>
    <div v-if="point.loading"><div class="ui active inline loader"></div></div>
  </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Points',
  components: {
    Wrapper,
    DownloadCSV,
  },
  data: function () {
    const limit = 30
    const columns = {
      'name': 'ポイント名',
      'owner_name': '発行者名',
      'balances': '残高',
      'amount': '発行数',
      'number_of_holders': 'ホルダー数',
      'last_transacted_at': '最終取引日',
      'owner_last_signed_in_at': '最終ログイン日',
      'passed_time': '最終経過日',
      'created_at': '登録日',
      'id': '詳細'
    }
    return {
      columns: columns,
      limit: limit,
      orderBy: '-updated_at',
      keyword: '',
    }
  },
  computed: {
    ...mapState([ 'point', ]),
  },
  created () {
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.point.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('point/getNewPoints', {
        keyword: this.keyword,
        limit: this.limit,
        order_by: this.orderBy,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.point.all) return
      this.$store.dispatch('point/getNextPoints', {
        keyword: this.keyword,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.point.sinceId,
      })
    }
  },
}
</script>
