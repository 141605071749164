import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from '@/components/Dashboard.vue'
import Holders from '@/components/Holders.vue'
import HolderDetail from '@/components/HolderDetail.vue'
import PointTransactions from '@/components/PointTransactions.vue'
import TransactionDetail from '@/components/TransactionDetail.vue'
import Benefits from '@/components/Benefits.vue'
import BenefitDetail from '@/components/BenefitDetail.vue'
import CreateBenefit from '@/components/CreateBenefit.vue'
import Campaigns from '@/components/Campaigns.vue'
import CampaignDetail from '@/components/CampaignDetail.vue'
import Stamps from '@/components/Stamps.vue'
import StampDetail from '@/components/StampDetail.vue'
import CreateStamp from '@/components/CreateStamp.vue'
import GenerateStampPDF from '@/components/GenerateStampPDF.vue'
import Login from '@/components/Login.vue'
import Callback from '@/components/Callback.vue'
import Root from '@/components/Root.vue'
import Notices from '@/components/Notices.vue'
import NoticeDetail from '@/components/NoticeDetail.vue'
import CreateNotice from '@/components/CreateNotice.vue'
import Points from '@/components/Points.vue'
import PendingUsers from '@/components/PendingUsers.vue'
import Users from '@/components/Users.vue'
import UserDetail from '@/components/UserDetail.vue'
import EditWebLogin from '@/components/EditWebLogin.vue'

Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      component: Root,
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/callback',
      component: Callback,
    },
    {
      path: '/pending-users',
      component: PendingUsers,
    },
    {
      path: '/campaigns',
      component: Campaigns,
    },
    {
      path: '/campaigns/:campaignId',
      component: CampaignDetail,
    },
    {
      path: '/users',
      component: Users,
    },
    {
      path: '/users/:userId',
      component: UserDetail,
    },
    {
      path: '/points',
      component: Points,
    },
    {
      path: '/points/:pointId',
      component: Dashboard,
    },
    {
      path: '/points/:pointId/holders',
      component: Holders,
    },
    {
      path: '/points/:pointId/holders/:holderId',
      component: HolderDetail,
    },
    {
      path: '/points/:pointId/transactions',
      component: PointTransactions,
    },
    {
      path: '/points/:pointId/transactions/:transactionId',
      component: TransactionDetail,
    },
    {
      path: '/points/:pointId/benefits',
      component: Benefits,
    },
    {
      path: '/points/:pointId/benefits/:benefitId',
      component: BenefitDetail,
    },
    {
      path: '/points/:pointId/create-benefit',
      component: CreateBenefit,
    },
    {
      path: '/points/:pointId/campaigns',
      component: Campaigns,
    },
    {
      path: '/points/:pointId/campaigns/:campaignId',
      component: CampaignDetail,
    },
    {
      path: '/points/:pointId/notices',
      component: Notices,
    },
    {
      path: '/points/:pointId/notices/:noticeId',
      component: NoticeDetail,
    },
    {
      path: '/points/:pointId/create-notice',
      component: CreateNotice,
    },
    {
      path: '/points/:pointId/stamps',
      component: Stamps,
    },
    {
      path: '/points/:pointId/stamps/:stampId',
      component: StampDetail,
    },
    {
      path: '/points/:pointId/create-stamp',
      component: CreateStamp,
    },
    {
      path: '/points/:pointId/generate-stamp-pdf',
      component: GenerateStampPDF,
    },
    {
      path: '/points/:pointId/edit-web-login',
      component: EditWebLogin,
    }
  ],
})
