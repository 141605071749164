import api from '../../api'

const state = {
  site: null,
}

const getters = {}

const actions = {
  async getPortal ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getPortal, payload)
    if (res !== null) {
      const site = res.data.site
      commit('setSite', site)
    }
  },
  async updatePortal ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.updatePortal, payload)
    if (res !== null) {
      const site = res.data.site
      commit('setSite', site)
      return res
    }
    return null
  },
}

const mutations = {
  setSite (state, site) {
    state.site = site
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}