<template>
  <div class="tickets">

    <div class="ui form">
      <div class="field">
        <select v-model="orderBy" class="ui fluid dropdown">
          <option value="-updated_at">利用日の新しい順</option>
          <option value="updated_at">利用日の古い順</option>
          <option value="-created_at">更新の新しい順</option>
          <option value="created_at">更新の古い順</option>
        </select>
      </div>
      <div class="field">
        <div class="ui teal basic button" @click="fetchNew">表示</div>
      </div>
      <div class="field">
        <DownloadCSV
          :resources="ticket.tickets"
          :columns="columns"
          :fileName="'tickets.csv'"
        />
      </div>
    </div>

    <table class="ui celled striped table">
    <thead><tr>
      <th v-for="value in columns" :key="value.id">
        {{ value }}
      </th>
    </tr></thead>
    <tbody><tr v-for="ticket in ticket.tickets" :key="ticket.id">
      <td v-for="(value, key) in columns" :key="value.id">
        <div v-if="key=='created_at' || key=='updated_at'">
          {{ ticket[key] | formatDate }}
        </div>
        <div v-else-if="key==='point_id'">
          <router-link :to="{ path: '/points/' + ticket[key] }" class="ui teal basic button">ポイント詳細</router-link>
        </div>
        <div v-else-if="key==='give'">
          <div class="ui form"><div class="field"><input v-model="ticket.screen_name" placeholder="スクリーンネーム"/></div></div>
          <button class="ui teal basic button" @click="giveTicket(ticket.screen_name, ticket.id, ticket.state)">指定ユーザーに譲渡する</button>
        </div>
        <div class="global-without-ignoring-br" v-else> {{ ticket[key] }} </div>
      </td>
    </tr></tbody>
    </table>
    <div v-if="ticket.loading"><div class="ui active inline loader"></div></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Tickets',
  components: {
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const limit = 30
    const columns = {
      'name': '特典名',
      'state': '状態',
      'feature': '種類',
      'email': 'メールアドレス',
      'message': 'メッセージ',
      'created_at': '交換日',
      'updated_at': '最終更新日',
      'point_id': 'ポイント詳細',
      'give': '譲渡',
    }
    return {
      columns: columns,
      limit: limit,
      orderBy: '-updated_at',
    }
  },
  props: {
    userId: String,
  },
  computed: {
    ...mapState([ 'ticket', ]),
  },
  created () {
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.ticket.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('ticket/getNewTickets', {
        user_id: this.userId,
        limit: this.limit,
        order_by: this.orderBy,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.ticket.all) return
      this.$store.dispatch('ticket/getNextTickets', {
        user_id: this.userId,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.ticket.sinceId,
      })
    },
    async giveTicket(screenName, ticketId, state) {
      if (state !== '未使用') {
        alert('使用済みのチケットは譲渡できません')
        return
      }
      if (!window.confirm(`スクリーンネーム ${screenName} のユーザーに該当チケットを譲渡しますか？`)) {
        return
      }
      await this.$store.dispatch('ticket/give', {
        id: ticketId,
        screen_name: screenName,
      })
      alert('譲渡しました')
    },
  },
}
</script>