import api from '../../api'

const state = {
  ticket: null,
  tickets: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  /*
  async getTicket ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTicket, payload)
    if (res !== null) {
      const ticket = res.data.ticket
      commit('setTicket', ticket)
    }
    commit('setLoading', false)
  },
  */
  async getNewTickets ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTickets, payload)
    if (res !== null) {
      const tickets = res.data.tickets
      commit('setTickets', tickets)
      if (tickets.length > 0) {
        commit('setSinceId', tickets[tickets.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextTickets ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTickets, payload)
    if (res !== null) {
      const tickets = res.data.tickets
      commit('appendTickets', tickets)
      if (tickets.length > 0) {
        commit('setSinceId', tickets[tickets.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  /* eslint no-unused-vars: 0 */
  async give ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.giveTicket, payload)
    if (res !== null) {
      // nothing
    }
  },
}

const mutations = {
  setTicket (state, ticket) {
    state.ticket = modify(ticket)
  },
  setTickets (state, tickets) {
    let tckts = []
    tickets.forEach(tckt => {
      tckts.push(modify(tckt))
    })
    state.tickets = tckts
  },
  appendTickets (state, tickets) {
    tickets.forEach(ticket => {
      state.tickets.push(modify(ticket))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

function modify(tckt) {
  let t = tckt
  t.state =
    tckt.state === 'issued' ? '未使用' :
    tckt.state === 'used' ? '使用済み' :
    '特典贈与済み'
  t.feature =
    tckt.feature === 'remote' ? 'オンライン' : 'オフライン'
  t.point_id = tckt.org
  return t
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
