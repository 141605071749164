<template>
  <div class="notice-detail">
    <Wrapper :title="'お知らせ詳細'">
      <div v-if="notice.loading"><div class="ui active inline loader"></div></div>
      <div v-else>
        <table class="ui celled striped table">
          <tbody>
            <tr v-for="(value, key) in contents" :key="value">
              <td>{{ value }}</td>
              <td>
                <div v-if="key==='release_time'">
                  {{ notice.notice[key] | formatMinute }}
                </div>
                <div v-else-if="key==='image_url'">
                  <div v-if="notice.notice[key]">
                    <img :src="notice.notice[key]" width="50%"/>
                  </div>
                  <div v-else>
                    なし
                  </div>
                </div>
                <div v-else-if="key==='delete'">
                  <button class="ui teal basic button" @click="deleteNotice">お知らせを削除する</button>
                </div>
                <div class="global-without-ignoring-br" v-else> {{ notice.notice[key] }} </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'

export default {
  name: 'NoticeDetail',
  components: {
    Wrapper: Wrapper,
  },
  data: function () {
    const contents = {
      'release_time': '公開日時',
      'body': '内容',
      'amount': '閲覧に必要なポイント数',
      'image_url': '画像',
      'delete': '削除',
    }
    return {
      noticeId: null,
      contents: contents,
    }
  },
  computed: mapState([
    'notice', 'point',
  ]),
  created () {
    this.noticeId = this.$route.params.noticeId
    this.$store.dispatch('notice/getNotice', {
      id: this.noticeId,
    })
  },
  methods: {
    async deleteNotice () {
      if (!window.confirm('このお知らせを削除します。よろしいですか？')) {
        return
      }
      await this.$store.dispatch('notice/deleteNotice', {
        id: this.noticeId,
      })
      alert('お知らせを削除しました')
      this.$router.push(`/points/${this.point.point.id}/notices`)
    },
  }
}
</script>