<template>
  <div class="inner-menu">
    <div class="ui vertical menu">
        <div class="item">
          <div class="header">ポイント管理</div>
          <div class="menu">
            <router-link class="item" :class="{'active': title === 'ダッシュボード'}" :to="'/points/' + pointId">ダッシュボード</router-link>
            <router-link class="item" :class="{'active': title === '保有者一覧'}" :to="'/points/' + pointId + '/holders'">保有者一覧</router-link>
            <router-link class="item" :class="{'active': title === 'QRコード一覧'}" :to="'/points/' + pointId + '/stamps'">QRコード一覧</router-link>
            <router-link class="item" :class="{'active': title === 'QRコード作成'}" :to="'/points/' + pointId + '/create-stamp'">QRコード作成</router-link>
            <router-link class="item" :class="{'active': title === 'QRコード印刷'}" :to="'/points/' + pointId + '/generate-stamp-pdf'">QRコード印刷</router-link>
            <router-link class="item" :class="{'active': title === 'キャンペーン一覧'}" :to="'/points/' + pointId + '/campaigns'">キャンペーン一覧</router-link>
            <router-link class="item" :class="{'active': title === '送受信履歴'}" :to="'/points/' + pointId + '/transactions'">送受信履歴</router-link>
          </div>
        </div>
        <div class="item">
          <div class="header">特典管理</div>
          <div class="menu">
            <router-link class="item" :class="{'active': title === '特典一覧'}" :to="'/points/' + pointId + '/benefits'">特典一覧</router-link>
            <router-link class="item" :class="{'active': title === '特典作成'}" :to="'/points/' + pointId + '/create-benefit'">特典作成</router-link>
          </div>
        </div>
        <div class="item">
          <div class="header">お知らせ管理</div>
          <div class="menu">
            <router-link class="item" :class="{'active': title === 'お知らせ一覧'}" :to="'/points/' + pointId + '/notices'">お知らせ一覧</router-link>
            <router-link class="item" :class="{'active': title === 'お知らせをおくる'}" :to="'/points/' + pointId + '/create-notice'">お知らせをおくる</router-link>
          </div>
        </div>
        <div class="item">
          <div class="header">設定</div>
          <div class="menu">
            <router-link class="item" :class="{'active': title === 'ブラウザ版ログイン設定'}" :to="'/points/' + pointId + '/edit-web-login'">ログイン設定</router-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InnerMenu',
  props: {
    title: String,
  },
  data () {
    return {
      pointId: null,
    }
  },
  computed: mapState([
    'point',
  ]),
  created () {
    this.pointId = this.$route.params.pointId
  },
}
</script>
<style scoped>
.inner-menu {
  margin-top: 40px;
}
</style>