import api from '../../api'

const state = {
  campaign: null,
  campaigns: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getCampaign ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getCampaign, payload)
    if (res !== null) {
      const campaign = res.data.campaign
      commit('setCampaign', campaign)
    }
    commit('setLoading', false)
  },
  async getNewCampaigns ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getCampaigns, payload)
    if (res !== null) {
      const campaigns = res.data.campaigns
      commit('setCampaigns', campaigns)
      if (campaigns.length > 0) {
        commit('setSinceId', campaigns[campaigns.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextCampaigns ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getCampaigns, payload)
    if (res !== null) {
      const campaigns = res.data.campaigns
      commit('appendCampaigns', campaigns)
      if (campaigns.length > 0) {
        commit('setSinceId', campaigns[campaigns.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  }
}

const mutations = {
  setCampaign (state, campaign) {
    state.campaign = modify(campaign)
  },
  setCampaigns (state, campaigns) {
    let cms = []
    campaigns.forEach(cm => {
      cms.push(modify(cm))
    })
    state.campaigns = cms
  },
  appendCampaigns (state, campaigns) {
    campaigns.forEach(cm => {
      state.campaigns.push(modify(cm))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

function modify(cm) {
  let c = cm
  c.type =
    cm.spec === 'once_daily' ? 'ひとり1日1回まで' :
    'なし'
  return c
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}