<template>
  <div class="callback">
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Callback',
  async mounted() {
    const store = localStorage
    const clientId = process.env.VUE_APP_CLIENT_ID
    const redirectUri = store.getItem('redirect_uri')
    const scope = store.getItem('scope')
    const codeVerifier = store.getItem('code_verifier')
    const code = new URLSearchParams(document.URL.replace(/^.*\?/, '')).get('code')
    const grantType = 'authorization_code'
    await this.$store.dispatch('auth/getAccessToken', {
      grant_type: grantType,
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: scope,
      code: code,
      code_verifier: codeVerifier,
    })
    this.$router.push(`/points`)
  },
  computed: { 
    ...mapState(['point']),
  },
}
</script>