import api from '../../api'

const state = {
  transaction: null,
  transactions: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getTransaction ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTransaction, payload)
    if (res !== null) {
      const transaction = res.data.journal
      commit('setTransaction', transaction)
    }
    commit('setLoading', false)
  },
  async getNewTransactions ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTransactions, payload)
    if (res !== null) {
      const transactions = res.data.journals
      commit('setTransactions', transactions)
      if (transactions.length > 0) {
        commit('setSinceId', transactions[transactions.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextTransactions ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getTransactions, payload)
    if (res !== null) {
      const transactions = res.data.journals
      commit('appendTransactions', transactions)
      if (transactions.length > 0) {
        commit('setSinceId', transactions[transactions.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  }
}

const mutations = {
  setTransaction (state, transaction) {
    state.transaction = modify(transaction)
  },
  setTransactions (state, transactions) {
    let txs = []
    transactions.forEach(tx => {
      txs.push(modify(tx))
    })
    state.transactions = txs
  },
  appendTransactions (state, transactions) {
    transactions.forEach(tx => {
      state.transactions.push(modify(tx))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

function modify(tx) {
  let t = Object.assign({}, tx)
  t.from_name = tx.from.name
  t.to_name = tx.to.name
  t.to_wallet = tx.to.wallet
  // meta 書き換え
  if (tx.meta) {
    let m = {}
    if (tx.meta.latitude) {
      m['取得緯度'] = tx.meta.latitude
    }
    if (tx.meta.longitude) {
      m['取得経度'] = tx.meta.longitude
    }
    t.meta = m
    t.message = tx.meta.message
  }
  return t
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}