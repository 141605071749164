<template>
  <div class="stamp-detail">
    <Wrapper :title="'QRコード詳細'">
      <div v-if="stamp.loading"><div class="ui active inline loader"></div></div>
      <div v-else>

        <div class="ui teal secondary pointing menu">
          <a @click="curItem=0" class="item" :class="{ active: curItem === 0 }">
            基本情報
          </a>
          <a @click="curItem=1" class="item" :class="{ active: curItem === 1 }">
            送受信履歴一覧
          </a>
          <a @click="curItem=2" class="item" :class="{ active: curItem === 2 }">
            QRコードを終了する
          </a>
          <a @click="curItem=3" class="item" :class="{ active: curItem === 3 }">
            このQRコードを再作成する
          </a>
        </div>

        <div class="ui teal segment">
          <div v-if="curItem===0">
            <div class="item">
              <table class="ui celled striped table">
                <tbody>
                  <tr v-for="(value, key) in dynamicContents" :key="value">
                    <td>{{ value }}</td>
                    <td>
                      <div v-if="key==='release_time' || key==='expiration_time'">
                        {{ stamp.stamp[key] | formatMinute }}
                      </div>
                      <div v-else-if="key==='url'">
                        <a :href="stamp.stamp[key]">{{ stamp.stamp[key] }}</a>
                      </div>
                      <div class="global-without-ignoring-br" v-else> {{ stamp.stamp[key] }} </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="curItem===1">
            <div class="item">
              <Transactions
                :stampId="stampId"
              />
            </div>
          </div>
          <div v-if="curItem===2">
            <div class="item">
              <div v-if="stamp.stamp.state==='終了済み'">
                このQRコードはすでに終了済みです
              </div>
              <div v-else>
                <EndStamp
                  :stampId="stampId"
                />
              </div>
            </div>
          </div>
          <div v-if="curItem===3">
            <div class="item">
              <RecreateStamp
                :stamp="stamp.stamp"
              />
            </div>
          </div>
        </div>

      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import Transactions from '@/components/Transactions.vue'
import EndStamp from '@/components/EndStamp.vue'
import RecreateStamp from '@/components/RecreateStamp.vue'

export default {
  name: 'StampDetail',
  components: {
    Wrapper: Wrapper,
    Transactions: Transactions,
    EndStamp: EndStamp,
    RecreateStamp: RecreateStamp,
  },
  data: function () {
    return {
      stampId: null,
      curItem: 0,
    }
  },
  computed: {
    ...mapState(['stamp', 'point', ]),
    dynamicContents () {
      let contents = {
        'amount': 'ポイント数',
        'type': '種類',
      }
      if (this.stamp.stamp.name) contents.name = '名前'
      if (this.stamp.stamp.description) contents.description = '説明'
      contents.total_amount_limit = '最大配布ポイント数'
      contents.total_holder_limit = '最大配布人数'
      contents.release_time = '開始日時'
      contents.expiration_time = '終了日時'
      contents.state = '状態'
      contents.message = 'メッセージ'
      contents.url = 'URL'
      return contents
    }
  },
  created () {
    this.stampId = this.$route.params.stampId
    this.$store.dispatch('stamp/getStamp', {
      id: this.stampId,
    })
  }
}
</script>
