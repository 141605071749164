<template>
  <div class="holder-detail">
    <Wrapper :title="'保有者詳細'">
      <div v-if="holder.loading"><div class="ui active inline loader"></div></div>
      <div v-else>
        <table class="ui celled striped table">
          <tbody>
            <tr v-for="(value, key) in contents" :key="value">
              <td>{{ value }}</td>
              <td>
                <div v-if="key==='created_at' || key==='updated_at'">
                  {{ holder.holder[key] | formatDate }}
                </div>
                <div v-else-if="key==='wallet' || key==='screen_name'">
                  <CopiableContent
                    :text="holder.holder[key]"
                    :name="key"
                  />
                </div>
                <div v-else>
                  {{ holder.holder[key] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="ui teal secondary pointing menu">
          <a @click="curItem=0" class="item" :class="{ active: curItem === 0 }">
            送受信履歴
          </a>
          <a @click="curItem=1" class="item" :class="{ active: curItem === 1 }">
            ポイント送信
          </a>
          <a @click="curItem=2" class="item" :class="{ active: curItem === 2 }">
            ポイントを発行者に戻す
          </a>
        </div>

        <div class="ui teal segment">
          <div v-if="curItem===0">
            <div class="item">
              <Transactions
                :walletId="holder.holder.wallet"
              />
            </div>
          </div>
          <div v-if="curItem===1">
            <div class="item">
              <SendPoint
                :screenName="holder.holder.screen_name"
              />
            </div>
          </div>
          <div v-if="curItem===2">
            <div class="item">
              <RetrievePoint
                :fromWallet="holder.holder.wallet"
              />
            </div>
          </div>
        </div>

      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import SendPoint from '@/components/SendPoint.vue'
import RetrievePoint from '@/components/RetrievePoint.vue'
import Transactions from '@/components/Transactions.vue'
import CopiableContent from '@/components/CopiableContent.vue'

export default {
  name: 'HolderDetail',
  components: {
    Wrapper: Wrapper,
    SendPoint: SendPoint,
    Transactions: Transactions,
    RetrievePoint: RetrievePoint,
    CopiableContent,
  },
  data: function () {
    const contents = {
      'name': '名前',
      'screen_name': 'スクリーンネーム',
      'wallet': 'ウォレット ID',
      'balances': '残高',
      'created_at': '初回獲得日',
      'updated_at': '直近の利用日',
    }
    return {
      pointId: null,
      holderId: null,
      contents: contents,
      curItem: 0,
    }
  },
  computed: mapState([
    'holder', 'point',
  ]),
  created () {
    this.pointId = this.$route.params.pointId
    if (!this.point.point || this.pointId !== this.point.point.id) {
      this.$store.dispatch('point/getPoint', {
        id: this.pointId,
      })
    }
    this.holderId = this.$route.params.holderId
    if (!this.holder.holder || this.holderId !== this.holder.holder.wallet) {
      this.$store.dispatch('holder/getHolder', {
        id: this.holderId,
      })
    }
  },
}
</script>
