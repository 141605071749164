<template>
  <div class="tweets">

  <div class="ui form">
    <div class="field">
      <select v-model="orderBy" class="ui fluid dropdown">
        <option value="-created_at">ツイートの新しい順</option>
        <option value="created_at">ツイートの古い順</option>
      </select>
    </div>
    <div class="field">
      <div class="ui teal basic button" @click="fetchNew">表示</div>
    </div>
    <div class="field">
      <DownloadCSV
        :resources="tweet.tweets"
        :columns="columns"
        :fileName="'tweets.csv'"
      />
    </div>
  </div>

  <table class="ui celled striped table">
  <thead><tr>
    <th v-for="value in columns" :key="value.id">
      {{ value }}
    </th>
  </tr></thead>
  <tbody><tr v-for="tweet in tweet.tweets" :key="tweet.id">
    <td v-for="(value, key) in columns" :key="value.id">
      <div v-if="key==='distributed_at'">
        {{ tweet[key] | formatMinute }}
      </div>
      <div v-else-if="key==='status'">
        <Tweet :id="tweet[key]"></Tweet>
      </div>
      <div v-else-if="key==='send_point'">
        <button class="ui teal basic button" @click="tweet.cnt = Math.min(10, tweet.cnt + 1)">+</button>
        <button class="ui teal basic button" @click="tweet.cnt = Math.max(1, tweet.cnt - 1)">-</button>
        <button class="ui teal basic button" @click="sendPoint(tweet.user_screen_name, amount * tweet.cnt)">
          追加で{{amount * tweet.cnt}}pt送る
        </button>
      </div>
      <div v-else>
        {{ tweet[key] }}
      </div>
    </td>
  </tr></tbody>
  </table>
  <div v-if="tweet.loading"><div class="ui active inline loader"></div></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Tweet } from 'vue-tweet-embed'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Tweets',
  data: function () {
    const limit = 30
    const columns = {
      'distributed_at': 'ツイート日時',
      'status': 'ツイート埋め込み',
      'user_name': 'mint ユーザー名',
      'user_screen_name': 'mint スクリーンネーム',
      'send_point': '追加送信',
    }
    return {
      pointId: null,
      columns: columns,
      limit: limit,
      orderBy: '-created_at',
      sinceId: null,
    }
  },
  props: {
    campaignId: String,
    amount: Number,
  },
  components: {
    Tweet: Tweet,
    DownloadCSV: DownloadCSV,
  },
  computed: mapState([
    'campaign', 'tweet',
  ]),
  created () {
    this.pointId = this.campaign.campaign.org
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.tweet.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('tweet/getNewTweets', {
        campaign_id: this.campaignId,
        limit: this.limit,
        order_by: this.orderBy,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.tweet.all) return
      this.$store.dispatch('tweet/getNextTweets', {
        campaign_id: this.campaignId,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.tweet.sinceId,
      })
    },
    async sendPoint (screenName, amount) {
      await this.$store.dispatch('point/send', {
        as_organizer: true,
        point_id: this.pointId,
        amount: amount,
        to_screen_name: screenName,
      })
      alert(`${screenName}さんに${amount}ptを追加送信しました`)
    },
  },
}
</script>