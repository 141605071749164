<template>
  <div class="point-detail">
    <div v-if="point.loading"><div class="ui active inline loader"></div></div>
    <div v-else>

      <div class="ui teal secondary pointing menu">
        <a @click="curItem=0" class="item" :class="{ active: curItem === 0 }">
          基本情報
        </a>
        <a @click="curItem=1" class="item" :class="{ active: curItem === 1 }">
          課金プランを変更する
        </a>
        <a @click="curItem=2" class="item" :class="{ active: curItem === 2 }">
          配布可能なポイント数を変更する
        </a>
        <a
          v-if="showCollect"
          @click="curItem=3"
          class="item"
          :class="{ active: curItem === 3 }"
        >
          ポイントを発行者に戻す
        </a>
      </div>

      <div class="ui teal segment">
        <div v-if="curItem===0">
          <table class="ui celled striped table">
            <tbody>
              <tr v-for="(value, key) in contents" :key="value">
                <td>{{ value }}</td>
                <td>
                  <div v-if="key==='created_at' || key==='updated_at'">
                    {{ point.point[key] | formatDate }}
                  </div>
                  <div v-else-if="key==='intro_stamp_url'">
                    <a :href="point.point[key]">{{ point.point[key] }}</a>
                  </div>
                  <div class="global-without-ignoring-br" v-else> {{ point.point[key] }} </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="curItem===1">
          <UpdateTokenPricingPlan />
        </div>
        <div v-if="curItem===2">
          <UpdateAmount />
        </div>
        <div v-if="curItem===3">
          <button
            :class="{'loading': collecting}"
            :disabled="collecting"
            @click="collect"
            class="ui teal basic button"
          >
            すべてのポイントを発行者に戻す
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UpdateTokenPricingPlan from '@/components/UpdateTokenPricingPlan.vue'
import UpdateAmount from '@/components/UpdateAmount.vue'

export default {
  name: 'PointDetail',
  data: function () {
    const contents = {
      'name': 'ポイント名',
      'owner_name': '発行者',
      'owner_description': '発行者の説明',
      'balances': '残りポイント数',
      'amount': '発行数',
      'updated_at': '最終更新日',
      'created_at': '登録日',
      'intro_stamp_url': '招待用URL',
      'pricing_plan': '課金プラン',
    }
    return {
      contents: contents,
      pointId: null,
      curItem: 0,
      collecting: false,
    }
  },
  components: {
    UpdateTokenPricingPlan: UpdateTokenPricingPlan,
    UpdateAmount: UpdateAmount,
  },
  computed: {
    ...mapState([
      'point',
    ]),
    showCollect () {
      if (!this.point.point) {
        return false
      }
      const valid = new Set([
        '809113ec-6f0d-44b7-be6b-40aa7298a8da',
        'ad48b82d-583e-4009-b124-577de83fdab3',
        '02a8ac7e-ee86-46e2-8166-c9cd6ed7f104',
      ])
      return valid.has(this.point.point.id)
    },
  },
  created () {
    if (this.$route.params.pointId) {
      this.pointId = this.$route.params.pointId
      this.$store.dispatch('point/getPoint', {
        id: this.pointId,
      })
    }
  },
  methods: {
    async collect () {
      if (!window.confirm(`${this.point.point.name} のポイントを全て発行者に戻しますか？`)) {
        return
      }
      this.collecting = true
      await this.$store.dispatch('point/collect', {
        id: this.point.point.id,
      })
      alert('ポイントを全て発行者に戻しました')
      this.collecting = false
    },
  }
}
</script>
