import api from '../../api'

const state = {
  wallets: [],
  loading: false,
}

const getters = {}

const actions = {
  async getNewWallets ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getWallets, payload)
    if (res !== null) {
      const wallets = res.data.wallets
      commit('setWallets', wallets)
    }
    commit('setLoading', false)
  },
}

const mutations = {
  setWallets (state, wallets) {
    let wls = []
    wallets.forEach(wl => {
      wls.push(modify(wl))
    })
    state.wallets = wls
  },
  setLoading (state, loading) {
    state.loading = loading
  },
}

function modify(wl) {
  let w = wl
  w.point_id = wl.token.id
  w.point_name = wl.token.name
  return w
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
