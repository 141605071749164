<template>
  <div class="users">

  <Wrapper :title="'ユーザー一覧'" :hasInnerMenu="false">
    <div class="ui form">
      <div class="two fields">
        <div class="field box">
          <input v-model="keyword" placeholder="検索ワード"/>
        </div>
        <div class="field">
          <select v-model="type" class="ui fluid dropdown">
            <option value="email">メールアドレス</option>
            <option value="screen_name">スクリーンネーム</option>
            <option value="phone_number">電話番号</option>
          </select>
        </div>
      </div>
      <div class="field">
        <select v-model="orderBy" class="ui fluid dropdown">
          <option value="-updated_at">更新の新しい順</option>
          <option value="updated_at">更新の古い順</option>
          <option value="-created_at">作成の新しい順</option>
          <option value="created_at">作成の古い順</option>
        </select>
      </div>
      <div class="field">
        <div class="ui teal basic button" @click="fetchNew">表示</div>
      </div>
      <div class="field">
        <DownloadCSV
          :resources="user.users"
          :columns="columns"
          :fileName="'users.csv'"
        />
      </div>
    </div>

    <table class="ui celled striped table">
    <thead><tr>
      <th v-for="value in columns" :key="value.id">
        {{ value }}
      </th>
    </tr></thead>
    <tbody><tr v-for="user in user.users" :key="user.id">
      <td v-for="(value, key) in columns" :key="value.id">
        <div v-if="key=='password_registered_at'">
          {{ user[key] | formatDate }}
        </div>
        <div v-else-if="key==='phone_number'&&user[key]">
          {{ user[key].replace("+81", "0") }}
        </div>
        <div v-else-if="key==='id'">
          <router-link :to="{ path: '/users/' + user[key] }" class="ui teal basic button">詳細</router-link>
        </div>
        <div v-else>
          {{ user[key] }}
        </div>
      </td>
    </tr></tbody>
    </table>
    <div v-if="user.loading"><div class="ui active inline loader"></div></div>
  </Wrapper>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Wrapper from '@/components/Wrapper.vue'
import DownloadCSV from '@/components/DownloadCSV.vue'

export default {
  name: 'Users',
  components: {
    Wrapper: Wrapper,
    DownloadCSV: DownloadCSV,
  },
  data: function () {
    const limit = 30
    const columns = {
      'email': 'メールアドレス',
      'screen_name': 'スクリーンネーム',
      'phone_number': '電話番号',
      'password_registered_at': 'パスワード最終更新日',
      'id': '詳細'
    }
    return {
      columns: columns,
      keyword: '',
      type: 'email',
      limit: limit,
      orderBy: '-updated_at',
    }
  },
  computed: {
    ...mapState([ 'user', ]),
  },
  created () {
    this.fetchNew()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.pager);
  },
  methods: {
    pager: function () {
      if (!this.user.loading && window.scrollY + document.documentElement.clientHeight >= document.documentElement.scrollHeight - 100) {
        this.fetchNext()
      }
    },
    fetchNew: function () {
      this.$store.dispatch('user/getNewUsers', {
        keyword: this.keyword,
        type: this.type,
        limit: this.limit,
        order_by: this.orderBy,
      })
      window.addEventListener('scroll', this.pager)
    },
    fetchNext: function () {
      if (this.user.all) return
      this.$store.dispatch('user/getNextUsers', {
        keyword: this.keyword,
        type: this.type,
        limit: this.limit,
        order_by: this.orderBy,
        since_id: this.user.sinceId,
      })
    }
  },
}
</script>