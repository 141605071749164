import Vue from 'vue'
import Vuex from 'vuex'
import point from './modules/point'
import holder from './modules/holder'
import transaction from './modules/transaction'
import benefit from './modules/benefit'
import campaign from './modules/campaign'
import stamp from './modules/stamp'
import tweet from './modules/tweet'
import auth from './modules/auth'
import notice from './modules/notice'
import image from './modules/image'
import pendingUser from './modules/pending-user'
import user from './modules/user'
import wallet from './modules/wallet'
import ticket from './modules/ticket'
import portal from './modules/portal'
import oidc from './modules/oidc'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    point,
    holder,
    transaction,
    benefit,
    campaign,
    tweet,
    stamp,
    auth,
    notice,
    image,
    pendingUser,
    user,
    wallet,
    ticket,
    portal,
    oidc,
  }
})
