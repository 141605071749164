<template>
  <svg
    :class="[this.name]"
    class="copy"
    data-content="Copied!"
    @click="copyToClipboard()"
  >
    <use
      class="icon"
      :xlink:href="`${remixiconUrl}#ri-clipboard-line`"
    />
  </svg>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      remixiconUrl,
    }
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    async copyToClipboard() {
      /* global $ */
      $(`.${this.name}`).popup('show')
      navigator.clipboard.writeText(this.text)
      await this.sleep(800)
      /* global $ */
      $(`.${this.name}`).popup('destroy')
    },
  },
}
</script>
<style scoped>
.copy {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>