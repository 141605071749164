import api from '../../api'

const state = {
  stamp: null,
  stamps: [],
  urls: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getStamp ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getStamp, payload)
    if (res !== null) {
      const stamp = res.data.stamp
      commit('setStamp', stamp)
    }
    commit('setLoading', false)
  },
  async getNewStamps ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getStamps, payload)
    if (res !== null) {
      const stamps = res.data.stamps
      commit('setStamps', stamps)
      if (stamps.length > 0) {
        commit('setSinceId', stamps[stamps.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextStamps ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getStamps, payload)
    if (res !== null) {
      const stamps = res.data.stamps
      commit('appendStamps', stamps)
      if (stamps.length > 0) {
        commit('setSinceId', stamps[stamps.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async createStamp ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.createStamp, payload)
    if (res !== null) {
      const stamp = res.data.stamp
      commit('setStamp', stamp)
    }
    commit('setLoading', false)
  },
  async createPDFStamp ({ commit }, payload) {
    commit('setLoading', true)
    commit('setURLs', [])
    for (let i = 0; i < payload.cnt; i ++) {
      for (let c = 0; c < 24; c ++) {
        const res = await api.tryAndIfTokenExpiredTryAgain(api.createStamp, payload)
        if (res !== null) {
          const stamp = res.data.stamp
          commit('appendURL', stamp.links[0].href)
        }
      }
      await api.sleep(500)
    }
    commit('setLoading', false)
  },
  async endStamp ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.endStamp, payload)
    if (res !== null) {
      const stamp = res.data.stamp
      commit('setStamp', stamp)
    }
  }
}

const mutations = {
  setStamp (state, stamp) {
    state.stamp = modify(stamp)
  },
  setStamps (state, stamps) {
    let sts = []
    stamps.forEach(st => {
      sts.push(modify(st))
    })
    state.stamps = sts
  },
  appendStamps (state, stamps) {
    stamps.forEach(st => {
      state.stamps.push(modify(st))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  },
  setURLs (state, urls) {
    state.urls = urls
  },
  appendURL (state, url) {
    state.urls.push(url)
  },
}

function modify(st) {
  let s = st
  s.type =
    st.spec === 'default' ? '1回のみ' :
    st.spec === 'intro' ? 'イントロ' :
    st.spec === 'intro_s8' ? 'パブスタ用' :
    st.spec === 'once_daily' ? '1日1回' :
    st.spec === 'community_store_intro' ? 'コミュニティストア イントロ' :
    st.spec === 'community_store_checkin' ? 'コミュニティストア チェックイン' :
    st.spec === 'no_limit' ? '読み取り回数無制限' :
    '不明'
  let now = new Date()
  let rt = Date.parse(st.release_time)
  let et = Date.parse(st.expiration_time)
  s.state =
    now < rt ? '未公開' :
    now < et ? '公開中' :
    '終了済み'
  s.url = st.links[0].href
  s.total_amount_limit =
    st.total_amount_limit === 0 ? '上限なし' : st.total_amount_limit
  s.total_holder_limit =
    st.total_holder_limit === 0 ? '上限なし' : st.total_holder_limit
  return s
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}