<template>
  <div class="edit-benefit">
    <div class="ui form">
      <div class="field">
        <h4>
          タイトル
          <span v-if="nameError===''&&name!==''" class="ok">
            <i class="check icon"></i>
          </span>
          <span v-else class="required"> *</span>
        </h4>
        <input @change="checkName" v-model="name" required/>
        <div v-if="nameError!==''" class="err">{{ nameError }}</div>
      </div>
      <div class="field">
        <h4>
          説明
          <span v-if="descriptionError===''&&description!==''" class="ok">
            <i class="check icon"></i>
          </span>
          <span v-else class="required"> *</span>
        </h4>
        <textarea @change="checkDescription" v-model="description" required/>
        <div v-if="descriptionError!==''" class="err">{{ descriptionError }}</div>
      </div>
      <div class="field">
        <h4>画像</h4>
        <div v-if="!uploadedImage&&isValidImage(benefit.profile_image_url)">
          <img :src="benefit.profile_image_url" width="50%">
        </div>
        <div v-else-if="uploadedImage&&file"><img :src="uploadedImage" width="50%"></div>
        <div v-else>なし</div>
        <input type="file" @change="onDrop"/>
      </div>
      <button :disabled="!allGood" class="ui teal basic button" @click="updateBenefit">上記内容で更新する</button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      name: this.benefit.name,
      nameError: '',
      description: this.benefit.description,
      descriptionError: '',
      file: null,
      uploadedImage: null,
    }
  },
  computed: {
    allGood() {
      let isGood = true
      isGood = isGood && (this.name !== '' && this.nameError === '')
      isGood = isGood && (this.description !== '' && this.descriptionError === '')
      let isChanged = false
      isChanged = isChanged || (this.name !== this.benefit.name)
      isChanged = isChanged || (this.description !== this.benefit.description)
      isGood = isGood && isChanged
      isGood = isGood || this.file
      return isGood
    },
  },
  methods: {
    onDrop (event) {
      this.file = event.target.files[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        this.uploadedImage = reader.result
      }
      if (this.file) {
        reader.readAsDataURL(this.file);
      }
    },
    isValidImage (url) {
      return url.match(/(jpg|jpeg|png)$/)
    },
    checkName () {
      const l = this.name.length
      const minl = 3
      const maxl = 20
      if (l < minl || maxl < l) {
        this.nameError = `タイトルは ${minl} 文字以上 ${maxl} 文字以下で指定してください`
        return
      }
      const validRegexp = /^[^&'<>]*$/
      if (!validRegexp.test(this.name)) {
        this.nameError = `タイトルに記号 & ' < > は使えません`
        return
      }
      this.nameError = ''
    },
    checkDescription () {
      const l = this.description.length
      const maxl = 1000
      if (maxl < l) {
        this.descriptionError = `説明は ${maxl} 文字以下で指定してください`
        return
      }
      const validRegexp = /^[^'<>]*$/
      if (!validRegexp.test(this.description)) {
        this.descriptionError = `説明に記号 ' < > は使えません`
        return
      }
      this.descriptionError = ''
    },
    async updateBenefit () {
      if (this.file) {
        let data = new FormData()
        data.append('id', this.benefit.profile_image.id)
        data.append('files[]', this.file)
        await this.$store.dispatch('image/uploadImage', data)
      }
      const updatedBenefit = await this.$store.dispatch('benefit/updateBenefit', {
        id: this.benefit.id,
        name: this.name,
        description: this.description,
      })
      if (updatedBenefit) {
        this.name = updatedBenefit.name
        this.description = updatedBenefit.description
        this.profile_image_url = updatedBenefit.profile_image.url
        alert('特典を更新しました')
      }
    },
  },
  props: {
    benefit: null,
  },
}
</script>
<style scoped>
.err {
  color: red;
}
.ok {
  color: #00cc00;
}
.required {
  color: red;
  font-size: 20px;
}
</style>