<template>
  <div>
    <h3>このQRコードを再作成する</h3>
    <p>
      このQRコードを終了させて同じQRコードを位置情報と取得可能距離のみ変更して再作成します<br>
      既に終了しているQRコードの場合は再作成のみ行います<br>
      コミュニティストアのチェックインスタンプ作成のみに使ってください<br>
      （開始日時と終了日時はそれぞれ「現在」と「現在から 10 年後」になります
    </p>
    <ValidationObserver v-slot="{ invalid }">
    <div class="ui form">
      <div>
        <div class="two fields">
          <div class="field">
            <h4>緯度<span class="required"> *</span></h4>
            <ValidationProvider
              name="緯度"
              rules="required|decimal"
              v-slot="{ errors }"
            >
              <input v-model="latitude"/>
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="field">
            <h4>経度<span class="required"> *</span></h4>
            <ValidationProvider
              name="経度"
              rules="required|decimal"
              v-slot="{ errors }"
            >
              <input v-model="longitude"/>
              <span class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="field">
          <h4>取得可能距離（単位は m です）<span class="required"> *</span></h4>
          <ValidationProvider
            name="取得可能距離"
            rules="required|decimal"
            v-slot="{ errors }"
          >
            <input v-model="capturable_distance"/>
            <span class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <button
          :class="{'loading': recreating}"
          :disabled="invalid"
          class="ui teal basic button"
          @click="recreate"
        >
          同じQRコードを再作成する
        </button>
      </div>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'RecreateStamp',
  data () {
    return {
      latitude: 0,
      longitude: 0,
      capturable_distance: 100,
      recreating: false,
    }
  },
  props: {
    stamp: Object,
  },
  methods: {
    async recreate () {
      if (!window.confirm('このQRコードを再作成しますか？')) {
        return
      }
      this.recreating = true

      const now = new Date()
      let exp = new Date()
      exp.setDate(exp.getDate() + 365 * 10 - 1)
      const et = this.formatTimePost(exp)
      const dist = this.capturable_distance / 1000.0
      if (Date.parse(this.stamp.expiration_time) > now) {
        await this.$store.dispatch('stamp/endStamp', {
          id: this.stamp.id,
        })
      }
      await this.$store.dispatch('stamp/createStamp', {
        token: this.stamp.token.id,
        amount: this.stamp.amount,
        name: this.stamp.name,
        description: this.stamp.description,
        total_amount_limit: 0,
        total_holder_limit: 0,
        spec: this.stamp.spec,
        release_time: null,
        expiration_time: et,
        latitude: Number(this.latitude),
        longitude: Number(this.longitude),
        capturable_distance: Number(dist),
        message: this.stamp.message
      })
      alert('再作成しました')
      this.recreating = false
    }
  }
}
</script>

<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>