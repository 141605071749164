<template>
  <div class="retrieve-point">
  <ValidationObserver v-slot="{ invalid }">
  <div class="ui form">
    <div class="field">
      <h4>ポイント数<span class="required"> *</span></h4>
      <ValidationProvider
        name="ポイント数"
        rules="required|numeric|between:1,1000"
        v-slot="{ errors }"
      >
        <input v-model="amount" />
        <span class="err">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <div class="field">
      <h4>ポイントを戻す理由・メモなど {{ message ? message.length : 0 }}/600</h4>
      <ValidationProvider
        name="ポイントを戻す理由・メモなど"
        rules="max:600"
        v-slot="{ errors }"
      >
        <textarea rows="4" cols="50" v-model="message" />
        <span class="err">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
    <button
      :disabled="invalid"
      class="ui teal basic button"
      @click="retrieve"
    >
      ポイントを発行者に戻す
    </button>
  </div>
  </ValidationObserver>

  </div>
</template>

<script>
export default {
  data () {
    return {
      amount: 1,
      message: '',
      pointId: null,
    }
  },
  props: {
    fromWallet: {
      type: String,
      required: true,
    },
  },
  created () {
    this.pointId = this.$route.params.pointId
  },
  methods: {
    async retrieve () {
      if (!window.confirm(`この保有者から${this.amount}ptを発行者に戻します。よろしいですか？`)) {
        return
      }
      let msg = this.message === '' ? null : this.message
      await this.$store.dispatch('point/retrieve', {
        id: this.pointId,
        amount: Number(this.amount),
        from_wallet: this.fromWallet,
        message: msg,
      })
      alert('ポイントを戻しました')
      this.$store.dispatch('holder/getHolder', {
        id: this.fromWallet,
      })
    }
  }
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>
