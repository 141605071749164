<template>
  <div class="edit-web-login">
    <Wrapper :title="'ブラウザ版ログイン設定'">
      <h4>ログイン設定</h4>
      <p>
        ブラウザ版でのログイン方法を設定できます。<br>
        無効にするときは既存の保有者のログインに影響が及ぶ可能性があるので注意して行ってください<br>
      </p>
      <div class="ui form">
        <div
          v-for="kind in kinds"
          class="login-item ui card"
          :key="kind"
        >
          <h4>{{ japaneseKind(kind) }}ログイン</h4>
          <div class="ui toggle checkbox">
            <input
              class="input-first"
              type="checkbox"
              @change="changed"
              :disabled="(useCount === 1) && use[kind]"
              v-model="use[kind]"
            />
            <label>{{ use[kind] ? '有効' : '有効にする' }}</label>
          </div>
        </div>
      </div>
      <div class="ui section divider"></div>
        <h4>LINEログイン設定</h4>
        <p>
          上のログインに他に LINE ログインの設定ができます。<br>
          利用する場合は有効にして Client ID と LIFF ID の設定をしてください。
        </p>
        <div class="login-item ui card">
        <h4>LINE ログイン</h4>
        <div class="ui toggle checkbox">
          <input
            type="checkbox"
            @change="changedLINE"
            v-model="useLINE"
          />
          <label>{{ useLINE ? '有効' : '有効にする' }}</label>
        </div>
        <div v-if="useLINE">
          <ValidationObserver v-slot="{ invalid }">
            <div class="ui form">
              <div class="field">
                <h4>Client ID<span class="required"> *</span></h4>
                <ValidationProvider
                  name="Client ID"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input v-model="clientID" />
                  <span class="err">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="field">
                <h4>LIFF ID<span class="required"> *</span></h4>
                <ValidationProvider
                  name="LIFF ID"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input v-model="liffID" />
                  <span class="err">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <button
                :class="{'loading': false}"
                :disabled="invalid"
                class="ui teal basic button"
                @click="updateLINE"
              >
                設定を更新する
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Wrapper from '@/components/Wrapper.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Wrapper,
  },
  data() {
    const kinds = ["email", "phoneNumber"]
    return {
      pointId: '',
      kinds,
      use: {
        email: false,
        phoneNumber: false,
      },
      useLINE: false,
      oidcID: '',
      clientID: '',
      liffID: '',
    }
  },
  async created () {
    // login
    this.pointId = this.$route.params.pointId
    await this.$store.dispatch('portal/getPortal', {
      id: this.pointId,
    })
    const methods = this.portal.site.ui_settings.login.methods
    methods.forEach(item => {
      this.use[item.type] = true
    })
    // LINE login
    const providers = this.portal.site.ui_settings.login.providers
    if (providers.length > 0) {
      this.liffID = providers[0].liff_id
      this.useLINE = true
    }
    await this.$store.dispatch('oidc/listOidc', {
      token_id: this.pointId,
    })
    if (this.oidc.connections.length > 0) {
      this.oidcID = this.oidc.connections[0].id
      this.clientID = this.oidc.connections[0].client_id
    }
  },
  computed: {
    ...mapState([
      'portal',
      'oidc',
    ]),
    useCount() {
      return this.kinds.filter(kind => this.use[kind]).length
    },
  },
  methods: {
    async updateLINE() {
      const oidcRes =
        this.oidcID !== '' ?
          await this.updateOIDC(this.oidcID, this.clientID) :
          await this.createOIDC(this.clientID)
      const settings = this.portal.site.ui_settings
      settings.login.providers = [{
        type: "line",
        liff_id: this.liffID,
      }]
      const providerRes = await this.updateSettings(settings)
      console.log(oidcRes, providerRes)
      if (oidcRes !== null && providerRes !== null) {
        alert('設定を更新しました')
      }
    },
    changedLINE() {
      if (!this.useLINE) {
        this.deleteLINE()
      }
    },
    async deleteLINE() {
      if (this.oidcID !== '') {
        this.deleteOIDC(this.oidcID)
      }
      if (this.liffID !== '') {
        const settings = this.portal.site.ui_settings
        settings.login.providers = []
        await this.updateSettings(settings)
      }
    },
    japaneseKind(kind) {
      if (kind === "email") {
        return "メールアドレス"
      } else if (kind === "phoneNumber") {
        return "電話番号"
      }
    },
    changed() {
      const settings = this.portal.site.ui_settings
      settings.login.methods = this.kinds
        .filter(kind => this.use[kind])
        .map(kind => {
          return {
            type: kind,
          }
        })
      this.updateSettings(settings)
    },
    async createOIDC(clientID) {
      const res = await this.$store.dispatch('oidc/createOidc', {
        token_id: this.pointId,
        client_id: clientID,
        provider: "line",
      })
      return res
    },
    async updateOIDC(oidcID, clientID) {
      const res = await this.$store.dispatch('oidc/updateOidc', {
        oidc_id: oidcID,
        client_id: clientID,
        provider: "line",
      })
      return res
    },
    async deleteOIDC(oidcID) {
      await this.$store.dispatch('oidc/deleteOidc', {
        oidc_id: oidcID,
      })
    },
    async updateSettings(settings) {
      let name = null
      if (this.portal.site.name) {
        name = this.portal.site.name
      }
      const res = await this.$store.dispatch('portal/updatePortal', {
        org: this.pointId,
        ui_settings: JSON.stringify(settings),
        name,
      })
      return res
    },
  },
}
</script>
<style>
.login-item.ui.card {
  padding: 20px;
}
.login-item:last-child {
  margin-top: 30px;
}
.ui.toggle.checkbox {
  margin-bottom: 5px;
}
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>