import api from '../../api'

const state = {
  pointId: '',
  point: null,
  points: [],
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getPoint ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getPoint, payload)
    if (res !== null) {
      const point = res.data.token
      commit('setPoint', point)
    }
    commit('setLoading', false)
  },
  async getPointByUserId ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getPointByUserId, payload)
    if (res !== null) {
      if (res.data.tokens.length > 0) {
        const point = res.data.tokens[0]
        commit('setPoint', point)
      } else {
        commit('setNullPoint')
      }
    }
    commit('setLoading', false)
  },
  async getNewPoints ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getPoints, payload)
    if (res !== null) {
      const points = res.data.tokens
      commit('setPoints', points)
      if (points.length > 0) {
        commit('setSinceId', points[points.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextPoints ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getPoints, payload)
    if (res !== null) {
      const points = res.data.tokens
      commit('appendPoints', points)
      if (points.length > 0) {
        commit('setSinceId', points[points.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async send ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.send, payload)
    console.log(commit)
    if (res !== null) {
      // nothing
    }
  },
  /* eslint no-unused-vars: 0 */
  async collect ({ commit }, payload) {
    const collectRes = await api.tryAndIfTokenExpiredTryAgain(api.collect, payload)
    if (collectRes !== null) {
      const res = await api.tryAndIfTokenExpiredTryAgain(api.getPoint, payload)
      if (res !== null) {
        const point = res.data.token
        commit('setPoint', point)
      }
    }
  },
  async updateTokenPricingPlan({ commit}, payload) {
    const updateRes = await api.tryAndIfTokenExpiredTryAgain(api.updateTokenPricingPlan, payload)
    if (updateRes !== null) {
      const res = await api.tryAndIfTokenExpiredTryAgain(api.getPoint, payload)
      if (res !== null) {
        const point = res.data.token
        commit('setPoint', point)
      }
    }
  },
  async updateAmount({ commit}, payload) {
    const updateRes = await api.tryAndIfTokenExpiredTryAgain(api.updateAmount, payload)
    if (updateRes !== null) {
      const res = await api.tryAndIfTokenExpiredTryAgain(api.getPoint, payload)
      if (res !== null) {
        const point = res.data.token
        commit('setPoint', point)
      }
    }
  },
  async retrieve ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.retrieve, payload)
    console.log(commit)
    if (res !== null) {
      // nothing
    }
  },
}

const mutations = {
  setPointId (state, id) {
    state.pointId = id
  },
  setPoint (state, point) {
    state.point = modify(point)
  },
  setNullPoint (state) {
    state.point = null
  },
  setPoints (state, points) {
    let pts = []
    points.forEach(pt => {
      pts.push(modify(pt))
    })
    state.points = pts
  },
  appendPoints (state, points) {
    points.forEach(pt => {
      state.points.push(modify(pt))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  },
}

function modify(pt) {
  let p = pt
  p.owner_name = pt.owner.name
  p.owner_description = pt.owner.description
  if (p.links) p.intro_stamp_url = p.links[0].href
  let now = new Date()
  let cmp = new Date()
  let lt = pt.last_transacted_at
  let ls = pt.owner_last_signed_in_at
  if (ls || lt) {
    p.last_active_at = !ls ? lt : (!lt ? ls : (ls < lt ? lt : ls))
  }
  let la = Date.parse(p.last_active_at)
  let d = now - la
  p.passed_time = (Math.floor(d / (1000 * 60 * 60 * 24))) + '日'
  p.active = cmp <= la
  return p
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
