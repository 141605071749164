<template>
  <div class="create-notice">

  <Wrapper :title="'お知らせをおくる'">
    <h3>お知らせをおくる</h3>
    <ValidationObserver v-slot="{ invalid }">
    <div class="ui form">
      <div class="field">
        <h4>内容<span class="required"> *</span> {{ body ? body.length : 0 }}/600</h4>
        <ValidationProvider
          name="内容"
          rules="required|max:600"
          v-slot="{ errors }"
        >
          <textarea rows="4" cols="50" v-model="body" />
          <span class="err">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="field">
        <h4>画像</h4>
        <input type="file" @change="onDrop"/>
      </div>
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" @change="initAmount" v-model="withAmount">
          <label>限定投稿</label>
        </div>
      </div>
      <div v-if="withAmount">
        <div class="field">
          <h4>何ポイント以上のユーザーにおくりますか？<span class="required"> *</span></h4>
          <ValidationProvider
            name="ポイント数"
            rules="required|numeric|between:0,10000"
            v-slot="{ errors }"
          >
            <input v-model="amount" />
            <span class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <br>
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" @change="initReleaseTime" v-model="withReleaseTime">
          <label>予約投稿(現仕様では予約投稿されたお知らせは配布されるまで削除できません。注意して作成してください)</label>
        </div>
      </div>
      <div v-if="withReleaseTime">
        <div class="field">
          <h4>お知らせをおくる日時</h4>
          <input v-model="releaseTime" type="datetime-local" min="2000-01-01T00:00" max="2100-01-01T00:00"/>
        </div>
      </div>
      <br>
      <button :disabled="invalid" class="ui teal basic button" @click="create">作成</button>
    </div>
    </ValidationObserver>
  </Wrapper>
  </div>
</template>

<script>
import Wrapper from '@/components/Wrapper.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Wrapper: Wrapper,
  },
  data: function () {
    return {
      pointId: '',
      amount: 0,
      body: '',
      file: null,
      withAmount: false,
      releaseTime: null,
      withReleaseTime: false,
    }
  },
  created: function () {
    this.pointId = this.$route.params.pointId
  },
  computed: mapState([
    'notice', 'image',
  ]),
  methods: {
    initAmount () {
      this.amount = 0
    },
    initReleaseTime () {
      this.releaseTime = null
    },
    onDrop: function (event) {
      this.file = event.target.files[0]
    },
    async create () {
      let rt = this.releaseTime + ':00+09:00'
      if (this.releaseTime === null) rt = null
      let imageId = await this.createImage()
      await this.$store.dispatch('notice/createNotice', {
        token: this.pointId,
        amount: Number(this.amount),
        body: this.body,
        release_time: rt,
        image: imageId,
      })
      alert('お知らせが作成されました。お知らせ一覧よりご確認ください') // FIXME error handling
    },
    async createImage() {
      if (this.file === null) {
        return null
      }
      await this.$store.dispatch('image/createImage', {
        service: 'news',
        usage: 'general',
      })
      let imageId = this.image.image.id
      let data = new FormData()
      data.append('id', imageId)
      data.append('files[]', this.file)
      await this.$store.dispatch('image/uploadImage', data)
      return imageId
    },
  },
}
</script>
<style scoped>
.err {
  color: red;
}
.required {
  color: red;
  font-size: 20px;
}
</style>