import api from '../../api'

const state = {
  benefit: null,
  benefits: [],
  sinceId: null,
  loading: false,
  all: false,
}

const getters = {}

const actions = {
  async getBenefit ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getBenefit, payload)
    if (res !== null) {
      const benefit = res.data.benefit
      commit('setBenefit', benefit)
    }
    commit('setLoading', false)
  },
  async getNewBenefits ({ commit }, payload) {
    commit('setLoading', true)
    commit('setAll', false)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getBenefits, payload)
    if (res !== null) {
      const benefits = res.data.benefits
      commit('setBenefits', benefits)
      if (benefits.length > 0) {
        commit('setSinceId', benefits[benefits.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async getNextBenefits ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.getBenefits, payload)
    if (res !== null) {
      const benefits = res.data.benefits
      commit('appendBenefits', benefits)
      if (benefits.length > 0) {
        commit('setSinceId', benefits[benefits.length - 1].id)
      } else {
        commit('setAll', true)
      }
    }
    commit('setLoading', false)
  },
  async createBenefit ({ commit }, payload) {
    commit('setLoading', true)
    const res = await api.tryAndIfTokenExpiredTryAgain(api.createBenefit, payload)
    if (res !== null) {
      const benefit = res.data.benefit
      commit('setBenefit', benefit)
      commit('setLoading', false)
      return res
    } else {
      commit('setLoading', false)
      return null
    }
  },
  async endPublication ({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.endBenefitPublication, payload)
    if (res !== null) {
      const benefit = res.data.benefit
      commit('setBenefit', benefit)
    }
  },
  async updateBenefit({ commit }, payload) {
    const res = await api.tryAndIfTokenExpiredTryAgain(api.updateBenefit, payload)
    if (res !== null) {
      const benefit = res.data.benefit
      commit('setBenefit', benefit)
      return benefit
    }
    return null
  },
}

const mutations = {
  setBenefit (state, benefit) {
    state.benefit = modify(benefit)
  },
  setBenefits (state, benefits) {
    let bns = []
    benefits.forEach(bn => {
      bns.push(modify(bn))
    })
    state.benefits = bns
  },
  appendBenefits (state, benefits) {
    benefits.forEach(bn => {
      state.benefits.push(modify(bn))
    })
  },
  setSinceId (state, sinceId) {
    state.sinceId = sinceId
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAll (state, all) {
    state.all = all
  }
}

function modify(bn) {
  let b = bn
  b.type = 
    bn.feature === 'remote' ? 'オンラインで使う' :
    bn.feature === 'in_place' ? '会って使う' :
    '会員証型'
  b.state =
    !bn.started ? '公開前' :
    !bn.ended ? '公開中' :
    '終了済み'
  b.visible =
    bn.is_visible ? '一般公開' :
    '一般非公開'
  b.profile_image_url = bn.profile_image.url
  return b
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}